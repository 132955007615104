import { IGetCategorys, IGetProductCategorys } from "src/Interfaces/organization";
import storefrontAxiosClient from "./storeFrontAxiosClient";

export const organizationAPI = {
  getCategorys: (params: IGetCategorys) => {
    const url = "organization/getCategorys";
    return storefrontAxiosClient.get(url, { params });
  },
  getProductCategorys: (params: IGetProductCategorys) => {
    const url = "organization/getProductCategorys";
    return storefrontAxiosClient.get(url, { params });
  },
};
