import { Route, useHistory, useLocation } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";

import { ICoupon, IFreePoint, IMerchant, IMerchantQRcode, IRoute } from "@interfaces";
import { LayoutApp } from "@layouts";
import {
  authRoutes,
  enumStatus,
  enumTypes,
  normalRoutes,
  PATH_404,
  PATH_CLIENT_LINK,
  PATH_COUPON,
  PATH_FREE_POINT,
  PATH_HISTORY_PUBLIC,
  PATH_HOME,
  PATH_SIGN_IN,
  PATH_SIGN_UP_FORM,
  PATH_TRAFFIC_SOURCE,
  PATH_WELCOME,
  publicRoutes,
} from "@configs";
import {
  getInfo,
  logout,
  openToast,
  selectAuth,
  selectConfigClient,
  selectMerchant,
  selectPage,
  setAuth,
  setLoading,
  setMainColor,
  setMerchant,
  setMerchantID,
  setPopupHistory,
} from "@redux";
import { merchantApi } from "@api";
import { useNotify } from "@utils";

const keyMerchantId = "merchant_id";
const keyHistoryId = "history_id";

export const ComponentAppRoute = (props: IRoute) => {
  //page props
  const { path, exact } = props;
  //page hook
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { error } = useNotify();
  //redux state
  const { auth, userInfo } = useSelector(selectAuth);
  const { externalType } = useSelector(selectPage);
  const { _id } = useSelector(selectMerchant);
  const configClient = useSelector(selectConfigClient);
  //WHAT: check user login
  const Component = props.component;
  const Layout = LayoutApp;

  const fetchInfo = async () => {
    if (auth?.accessToken) {
      const info = await dispatch(getInfo());
      //@ts-ignore
      unwrapResult(info);
      //@ts-ignore
      if (info.payload?.status === enumStatus.INACTIVE) {
        dispatch(
          openToast({
            message: t("page.need_update_info"),
            type: "warning",
            autoHideDuration: 2000,
          })
        );
        history.push(PATH_SIGN_UP_FORM);
      }
    }
  };

  const checkNormalRoute = () => {
    const isNormalRoute =
      pathname === PATH_HOME
        ? true
        : normalRoutes.some((item: string) => {
            if (item === PATH_HOME) {
              return false;
            }
            return pathname.includes(item);
          });

    if (isNormalRoute) {
      if (!auth?.accessToken && _id) {
        return history.push(PATH_SIGN_IN);
      } else if (!_id) return history.push(PATH_404);
    }
  };

  const checkAuthRoute = () => {
    const isAuthRoute = authRoutes.some((item: string) => pathname.includes(item));

    if (isAuthRoute) {
      if (auth && userInfo?.status === enumStatus.ACTIVE) history.push(PATH_HOME);
      else if (!_id) history.push(PATH_404);
    }
  };

  const checkExternalType = async () => {
    if (externalType && auth?.accessToken) {
      const info = await dispatch(getInfo());
      //@ts-ignore
      unwrapResult(info);

      //@ts-ignore
      if (info.payload?.status === enumStatus.ACTIVE) {
        if (externalType?.type === enumTypes.COUPON && externalType.id) {
          history.push(PATH_COUPON + "/" + externalType.id);
        } else if (externalType?.type === enumTypes.FREE_POINT && externalType.id) {
          history.push(PATH_FREE_POINT + "/" + externalType.id);
        }
      }
    }
  };

  const setInitMerchant = async () => {
    try {
      const merchantConfig = await merchantApi.getMerchantConfig();
      const merchant = merchantConfig.data.merchant as IMerchant;
      if (merchant) {
        if (!merchant.enableAccess) {
          // dispatch(resetMerchant());
          return history.push(PATH_404);
        } else {
          dispatch(setMerchant(merchant));
          dispatch(setMainColor(merchant.themeColor));
          const index = configClient.findIndex((item) => item.merchantId === merchant._id);
          if (index >= 0) {
            dispatch(setAuth(configClient[index].token));
          } else dispatch(logout());
        }
      }
    } catch {
      setMerchantID("");
      history.push(PATH_404);
    }
  };

  const getMerchantByCouponId = async (id: string) => {
    try {
      dispatch(setLoading(true));
      const res = await merchantApi.getCouponId(id);
      const data = res.data as ICoupon;
      dispatch(setMerchantID(data.merchantId));
      await setInitMerchant();
    } catch {
      history.push(PATH_404);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMerchantByFreePointId = async (id: string) => {
    try {
      dispatch(setLoading(true));
      const res = await merchantApi.getFreePointId(id);
      const data = res.data as IFreePoint;
      dispatch(setMerchantID(data.merchantId));
      await setInitMerchant();
    } catch (err: any) {
      if (err.response) {
        const mess = Array.isArray(err.response.data.errors)
          ? err.response.data.errors[0]
          : err.response.data.errors;
        error(mess);
      }
      history.push(PATH_404);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMerchantByClientLink = async (id: string) => {
    try {
      dispatch(setMerchantID(id));
      await setInitMerchant();
    } catch {
      history.push(PATH_404);
    } finally {
      history.push(PATH_WELCOME);
    }
  };

  const getMerchantByTrafficSource = async (id: string) => {
    try {
      const res = await merchantApi.getTrafficSourceId(id);
      const data = res.data as IMerchantQRcode;
      if (data.status !== enumStatus.ACTIVE) {
        history.push(PATH_404);
        return;
      }
      dispatch(setMerchantID(data.merchantId));
      await setInitMerchant();
    } catch {
      history.push(PATH_404);
    } finally {
      history.push(PATH_WELCOME);
    }
  };

  const getMerchantByPublicHistory = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const merchantId = searchParams.get(keyMerchantId);
    const historyId = searchParams.get(keyHistoryId);
    if (merchantId && historyId) {
      try {
        dispatch(setMerchantID(merchantId));
        await setInitMerchant();
      } catch {
        history.push(PATH_404);
      } finally {
        dispatch(setPopupHistory(true));
        history.push(PATH_WELCOME);
      }
    }
  };

  const checkPublicRoute = () => {
    const pathname = window.location.pathname;
    const isPublicRoute = publicRoutes.some((item: string) => pathname.includes(item));
    if (isPublicRoute) {
      const pathArr = pathname.substring(1).split("/", 2);
      const currentPath = "/" + pathArr[0];
      const id = pathArr[1] || undefined;
      if (id) {
        switch (currentPath) {
          case PATH_COUPON: {
            getMerchantByCouponId(id);
            break;
          }
          case PATH_FREE_POINT: {
            getMerchantByFreePointId(id);
            break;
          }
          case PATH_CLIENT_LINK: {
            getMerchantByClientLink(id);
            break;
          }
          case PATH_TRAFFIC_SOURCE:
            getMerchantByTrafficSource(id);
            break;
          default:
            break;
        }
      } else {
        if (currentPath === PATH_HISTORY_PUBLIC) {
          getMerchantByPublicHistory();
        }
      }
    }
  };

  const syncCheck = async () => {
    // get info
    await fetchInfo();
    // logic public route to get merchant
    checkPublicRoute();
    // //check normal route
    checkNormalRoute();
    // check auth route
    checkAuthRoute();

    // //check external type
    checkExternalType();
  };

  useEffect(() => {
    // syncCheck();
  }, []);

  return (
    <Route
      path={path}
      exact={exact}
      render={() => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  );
};
