import { IPagination, IReceive } from "@interfaces";
import axiosClient from "./axiosClient";

export const couponApi = {
    getListCoupon: (params: IPagination) => {
        const url = "/histories/received?type=COUPON";
        return axiosClient.get(url, { params });
    },
    // getListCouponMerchant: (params: IPagination) => {
    //     const url = "/histories/list-coupon";
    //     return axiosClient.get(url, { params });
    // },
    getListCouponMerchant: (params: IPagination) => {
        const url = "/coupons/client";
        return axiosClient.get(url, { params });
    },
    getPreviewCoupon: (id: string) => {
        const url = `/coupons/${id}/qrcode`;
        return axiosClient.get(url);
    },
    // receiveCoupon: (values: IReceive) => {
    //     const url = "/histories/received";
    //     return axiosClient.post(url, values);
    // },
    receiveCoupon: (values: IReceive) => {
        const url = "/coupons/received";
        return axiosClient.post(url, values);
    },
    // redeemedCoupon: (values: IReceive) => {
    //     const url = "/histories/redeemed";
    //     return axiosClient.post(url, values);
    // },
    redeemedCoupon: (values: IReceive) => {
        const url = "/coupons/redeemed";
        return axiosClient.post(url, values);
    },
    // redeemedCouponNotId: (values: IReceive, token: string) => {
    //     const url = `/histories/${token}/redeemed`;
    //     return axiosClient.post(url, values);
    // },
    redeemedCouponNotId: (values: IReceive, token: string) => {
        const url = `/coupons/${token}/redeemed`;
        return axiosClient.post(url, values);
    },
};
