interface IProps {
    color?: string;
}

export const IconBasket = (propse: IProps) => {
    return (
        <svg
            width="39"
            height="36"
            viewBox="0 0 39 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.62741 14.5108L9.43141 24.0735C9.49008 24.8095 10.0914 25.3615 10.8261 25.3615H10.8314H25.3794H25.3821C26.0767 25.3615 26.6701 24.8442 26.7687 24.1575L28.0354 15.4122C28.0647 15.2042 28.0127 14.9962 27.8861 14.8282C27.7607 14.6588 27.5767 14.5495 27.3687 14.5202C27.0901 14.5308 15.5261 14.5148 8.62741 14.5108ZM10.8234 27.3615C9.06741 27.3615 7.58074 25.9908 7.43808 24.2375L6.21674 9.71218L4.20741 9.36551C3.66208 9.26951 3.29808 8.75351 3.39141 8.20818C3.48741 7.66284 4.01408 7.30818 4.54741 7.39351L7.32074 7.87351C7.76741 7.95218 8.10741 8.32284 8.14608 8.77618L8.45941 12.5108C27.4941 12.5188 27.5554 12.5282 27.6474 12.5388C28.3901 12.6468 29.0434 13.0348 29.4887 13.6322C29.9341 14.2282 30.1207 14.9628 30.0141 15.6988L28.7487 24.4428C28.5101 26.1068 27.0647 27.3615 25.3847 27.3615H25.3781H10.8341H10.8234Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.2406 19.4393H19.5446C18.9913 19.4393 18.5446 18.9913 18.5446 18.4393C18.5446 17.8873 18.9913 17.4393 19.5446 17.4393H23.2406C23.7926 17.4393 24.2406 17.8873 24.2406 18.4393C24.2406 18.9913 23.7926 19.4393 23.2406 19.4393Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2498 30.9835C10.6511 30.9835 10.9751 31.3075 10.9751 31.7089C10.9751 32.1102 10.6511 32.4355 10.2498 32.4355C9.84715 32.4355 9.52315 32.1102 9.52315 31.7089C9.52315 31.3075 9.84715 30.9835 10.2498 30.9835Z"
                fill="white"
            />
            <mask id="mask0_1884_1129" maskUnits="userSpaceOnUse" x="9" y="30" width="2" height="3">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.52248 31.7083C9.52248 32.111 9.84648 32.4363 10.2505 32.4363C10.6518 32.4363 10.9758 32.111 10.9758 31.7083C10.9758 31.307 10.6518 30.983 10.2505 30.983C9.84648 30.983 9.52248 31.307 9.52248 31.7083Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_1884_1129)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.85715 39.1016H17.6425V24.3163H2.85715V39.1016Z"
                    fill="white"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2485 31.4354C10.0978 31.4354 9.97515 31.5581 9.97515 31.7087C9.97515 32.0114 10.5231 32.0114 10.5231 31.7087C10.5231 31.5581 10.3991 31.4354 10.2485 31.4354ZM10.2485 33.4354C9.29648 33.4354 8.52315 32.6607 8.52315 31.7087C8.52315 30.7567 9.29648 29.9834 10.2485 29.9834C11.2005 29.9834 11.9751 30.7567 11.9751 31.7087C11.9751 32.6607 11.2005 33.4354 10.2485 33.4354Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.2902 30.9835C25.6915 30.9835 26.0169 31.3075 26.0169 31.7089C26.0169 32.1102 25.6915 32.4355 25.2902 32.4355C24.8875 32.4355 24.5635 32.1102 24.5635 31.7089C24.5635 31.3075 24.8875 30.9835 25.2902 30.9835Z"
                fill="white"
            />
            <mask
                id="mask1_1884_1129"
                maskUnits="userSpaceOnUse"
                x="24"
                y="30"
                width="3"
                height="3"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.5638 31.7083C24.5638 32.111 24.8878 32.4363 25.2905 32.4363C25.6905 32.4363 26.0171 32.111 26.0171 31.7083C26.0171 31.307 25.6905 30.983 25.2905 30.983C24.8878 30.983 24.5638 31.307 24.5638 31.7083Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask1_1884_1129)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.8971 39.1016H32.6838V24.3163H17.8971V39.1016Z"
                    fill="white"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.2889 31.4354C25.1395 31.4354 25.0169 31.5581 25.0169 31.7087C25.0182 32.0141 25.5649 32.0114 25.5635 31.7087C25.5635 31.5581 25.4395 31.4354 25.2889 31.4354ZM25.2889 33.4354C24.3369 33.4354 23.5635 32.6607 23.5635 31.7087C23.5635 30.7567 24.3369 29.9834 25.2889 29.9834C26.2422 29.9834 27.0169 30.7567 27.0169 31.7087C27.0169 32.6607 26.2422 33.4354 25.2889 33.4354Z"
                fill="white"
            />
            <circle cx="29.9048" cy="9.57145" r="8.66667" fill="white" />
        </svg>
    );
};
