import styled from "styled-components";

export const StyledSHome = styled.div<{ mainColor: string }>`
  .preview {
    width: calc(100%);
    position: relative;
    background-color: #fff;
    /* background-image: url("/images/logoAuth.png"); */
    background-repeat: no-repeat;
    background-position: 12px 12px;
    margin: 0 auto;
    /* box-shadow: rgb(231 230 220) 1px 5px 14px 3px; */
    box-shadow: rgb(196 196 196 / 25%) 0px 0px 11px 2px;
    padding: 16px 22px;
    border-radius: 8px;
  }
  .SalonName {
    display: flex;
    flex-direction: column;
  }
  .fixBorderRadius {
    border-radius: 0;
  }
  .topSalonName h1 {
    font-size: 22px;
    color: #000;
    font-weight: 600;
    cursor: pointer;
  }
  #phone {
    cursor: pointer;
  }
  .topStoreInfo {
    display: flex;
  }
  .topSalonName h1 svg {
    margin-left: 10px;
  }
  .topSalonName {
    display: flex;
    place-content: space-between;
  }
  .topStoreInfo svg {
    margin-left: 25px;
  }
  .botSalonName p {
    font-size: 16px;
    /* color: #919191; */
    color: #919191;
    margin-bottom: 0;
    font-weight: 400;
    span {
      font-weight: 300;
    }
  }
  .popularSection {
    margin-top: 10px;
    padding: 16px 22px;
  }
  .secondH1 {
    font-weight: 600;
    font-size: 18px;
    color: #000;
    border-left: 3px solid ${(color) => color.mainColor};
    padding-left: 5px;
  }
  .swiper-slide {
    box-shadow: rgb(196 196 196 / 25%) 0px 0px 11px 2px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding-bottom: 10px;
  }
  .ServiceTitle {
    width: 100%;
    padding: 0 10px;
    text-align: left;
    margin-top: 10px;
  }
  .topServiceTitle {
    font-size: 15px;
    color: #000;
  }
  .priceAndArrow h2 {
    font-size: 18px;
    color: ${(color) => color.mainColor};
    font-weight: 600;
    margin: 0;
  }
  .priceAndArrow {
    display: flex;
    place-content: space-between;
    width: 100%;
    padding: 0 10px;
    /* margin-top: 5px; */
    margin-bottom: 0px;
    place-items: center;
    .staff-store {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-weight: 400;
    }
  }
  .swiperSection {
    width: 100%;
    padding-left: 12px;
  }
  .servicesSection {
    padding: 16px 22px 0 0;
    display: flex;
    min-height: 100vh;
  }
  .leftSide {
    display: flex;
    background: #f8f8f8;
    width: 105px;
    place-content: space-between;
  }
  .leftSide .nav-bar {
    padding: 0;
  }
  .nav-link {
    padding: 0;
    color: #000;
    border: none;
  }
  .leftSide .nav.nav-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .rightSide {
    width: 100%;
    padding-left: 20px;
    padding-bottom: 20px;
  }
  .nav-link.active {
    border: none;
    color: #000;
    background-color: #fff;
    font-weight: 600;
  }
  .leftSide .nav-item {
    border: none;
    color: #000;
    background-color: #f8f8f8;
    padding-right: 20px;
  }
  .nav-tabs {
    border: none;
  }
  .imageBannerSection img {
    width: 100%;
  }
  .swiper-wrapper {
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .coverImage img {
    border-radius: 8px;
    width: 110px;
    height: 110px;
    object-fit: cover;
  }
  .allServices {
    /* margin-top: 20px; */
    margin-bottom: 20px;
    display: flex;
  }
  .serviceInfo h1 {
    font-size: 18px;
    margin-bottom: 0.3rem;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .serviceInfo p {
    font-size: 16px;
    color: #919191;
    margin-bottom: 1.4rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: 400;
  }
  .serviceInfo {
    margin-left: 20px;
    width: 100%;
    position: relative;
    .staff-position {
      padding-bottom: 25px;
    }
  }
  .serviceInfo .priceAndArrow {
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 0;
    position: absolute;
    bottom: 5px;
    padding-right: 0;
    svg {
      margin-top: 5px;
    }
  }
  .topNav .nav-link {
    text-align: center;
    padding: 15px 0;
    font-size: 15px;
    line-height: 21px;
    color: #989898;
    background: transparent;
    cursor: pointer;
    position: relative;
    font-style: normal;
    height: 50px;
  }
  .topNav li {
    padding: 0 !important;
  }
  .topNav .nav-link.active {
    color: #000000;
    &::after {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 45%;
      width: 40%;
      height: 4px;
      border-radius: 10px;
      background-color: ${(color) => color.mainColor};
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .nav .item #home-tab {
    // services-tab
    font-size: 18px;
    font-weight: 600;
  }
  .nav .item #staff-tab {
    // staff-tab
    font-weight: 600;
    font-size: 18px;
  }
  .leftNavs .nav-item {
    /* padding: 5px 0; */
    padding: 0;
    padding-bottom: 5px;
    display: flex;
    place-content: center;
    height: 70px;
    a {
      /* background-color: pink; */
      padding: 0;
      padding-top: 20px;
      font-size: 16px;
      width: 100%;
      box-shadow: 0px 24px 3px -24px gray;
    }
  }
  .leftNavs .nav-link {
    display: flex;
    flex-direction: column;
    place-items: center;
    padding: 10px 0;
  }
  .leftNavs .nav-link svg {
    margin-bottom: 10px;
  }
  .leftNavs .nav-link.active {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .leftSide .nav-link.active {
    &::after {
      // black line of left side menu
      content: "";
      position: absolute;
      left: 1px;
      margin-top: -20px;
      width: 2px;
      height: 65px;
      border-radius: 8px;
      background-color: #000;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .noTopPadding {
    padding-top: 0 !important;
  }

  // Staff Section
  .staffSection {
    padding: 0 20px;
    padding-top: 20px;
  }
  .staffSection .coverImage img {
    width: 85px;
    height: 85px;
    object-fit: cover;
  }
  .staffSection .serviceInfo p {
    margin-bottom: 0;
    display: flex;
  }
  .staffSection .serviceInfo p span {
    margin-left: 5px;
  }
  .staffSection .serviceInfo {
    padding: 8px 0;
  }
  .staffSection .serviceInfo h1 {
    margin-bottom: 0.4rem;
  }
  .staff-btn-section {
    display: flex;
    a {
      display: inline-block;
      align-self: flex-end;
      padding-bottom: 10px;
    }
  }
  .staffButton {
    background: #fff;
    border-radius: 20px;
    border: 1px solid ${(color) => color.mainColor}a9;
    color: ${(color) => color.mainColor};
    width: 85px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    /* margin-top: 50px; */
  }

  .nav-bar {
    padding: 3px 22px;
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    // margin-top: 10px;
    justify-content: flex-start;
    .item {
      /* width: 25%; */
      text-align: center;
      padding: 15px 0;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      color: #989898;
      background: transparent;
      cursor: pointer;
      position: relative;
      margin-right: 25px;
      font-style: normal;
    }
    .under {
      color: #000000;
      font-weight: 600;
      &::after {
        content: "";
        position: absolute;
        bottom: 8px;
        left: 45%;
        width: 50%;
        height: 2px;
        background-color: ${(color) => color.mainColor};
        transform: translateX(-50%);
      }
    }
  }
  // Walkin Codes
  .threeTab .tab {
    background: #fff;
    border-radius: 16px;
    border: 0.5px solid #9daaae;
    padding: 16px 20px;
    display: flex;
    place-content: space-between;
    place-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
  }
  .threeTab .tab h3 {
    font-size: 16px;
    margin-bottom: 0;
    color: ${(color) => color.mainColor};
    span {
      color: #9daaae;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
  }
  .rightArrow {
    position: absolute;
    right: 10px;
  }
  .threeTab .arrowIcon {
    font-weight: 600;
    font-size: 18px;
  }
  .threeTab .btn {
    background: #fff;
  }
  .wholeGrayPage {
    background: #f7f7f8;
    padding: 20px;
  }
  // Walkin Codes
  // Shop Choosing
  .shopDiv {
    cursor: pointer;
  }
  .shopDiv .leftShopDetail {
    color: black;
    display: flex;
    gap: 8px;
  }
  .shopDiv .leftShopDetail h3 {
    font-size: 16px;
    font-weight: 500;
  }
  .shopDiv .leftShopDetail p {
    font-size: 14px;
    font-weight: 400;
  }
  .shopDiv .leftShopDetail .small {
    font-size: 12px;
    color: #a5a5a5;
  }
  .province-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    place-items: center;
    .select-province-title {
      font-size: 15px;
      font-weight: 600;
      margin-top: 6px;
      width: 100%;
    }
    .select-province {
      width: 100%;
      height: 48px;
      .ant-select-selection {
        height: 43px;
        padding: 5px;
      }
    }
  }

  .rightShopDetail {
    height: 100%;
    border-left: 1px solid #a5a5a5;
    display: flex;
    padding: 20px;
  }
  .rightShopDetail p {
    margin: 0;
    font-weight: 600;
  }
  // Shop Choosing
  // Store details
  .topStoreInfo2 {
    display: flex;
    width: 100%;
    place-items: center;
  }
  .circle {
    background: ${(color) => color.mainColor};
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 20px;
    display: flex;
    place-content: center;
    place-items: center;
  }
  .wholePage {
    padding: 20px;
  }
  .topStoreInfo2 .circle h1 {
    font-size: 12px;
    color: #fff;
    text-align: center;
    margin: 0;
  }
  .rightOfLogoSide h1 {
    font-size: 16px;
  }
  .rightOfLogoSide h2 {
    font-size: 14px;
    color: #646464;
    margin: 0;
  }
  .pinRow {
    display: flex;
    place-content: space-between;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pinSide {
    display: flex;
  }
  .pinSide svg {
    margin-right: 20px;
    margin-top: 2px;
  }
  .pinSide p {
    font-size: 14px;
    color: #646464;
  }
  .customHR {
    border-top: none;
    height: 10px;
    background: #f7f7f8;
  }
  .phoneRow {
    display: flex;
    place-content: space-between;
    place-items: center;
  }
  .leftPhoneRow {
    display: flex;
  }
  .phoneRow svg {
    margin-right: 20px;
  }
  .phoneRow p {
    font-size: 14px;
    color: #646464;
    margin: 0;
  }
  .openningRow {
    margin-top: 20px;
    display: flex;
    gap: 3rem;
  }
  .openningRow svg {
    margin-right: 20px;
  }
  .openningTime h1 {
    font-size: 14px;
  }
  .openningTime p {
    font-size: 14px;
    color: #646464;
  }
  .firstOpenning {
    display: flex;
  }
  // Store details
`;

export const StyledContainerSHome = styled.div`
  /* min-height: 80vh; */
  background-color: transparent;
  .ant-empty {
    margin-top: 25px;
  }
`;
