import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
    selectConfigClient,
    setMainColor,
    setMerchant,
    setMerchantID,
    setPopupHistory,
} from "@redux";
import { PageSignIn, PageHome } from "@pages";
import { merchantApi } from "@api";
import { IMerchant } from "@interfaces";
import { PATH_404 } from "@configs";

enum enumConfigMerchant {
    INIT = 0,
    TRUE = 1,
    FALSE = 2,
}

export const PageSwitchHistoryPublic = () => {
    // states
    const [isConfig, setIsConfig] = useState<enumConfigMerchant>(enumConfigMerchant.INIT);
    const [data, setData] = useState<IMerchant>();
    // redux states
    const configClient = useSelector(selectConfigClient);
    // hooks
    const dispatch = useDispatch();
    const history = useHistory();
    //page variables
    const link = new URL(location.href);
    const merchantID = link.searchParams.get("merchant_id");
    const historyId = link.searchParams.get("history_id");

    useEffect(() => {
        checkPublicHistoryURL();
    }, []);

    const checkPublicHistoryURL = async () => {
        if (merchantID && historyId) {
            try {
                dispatch(setMerchantID(merchantID || ""));
                const merchantConfig = await merchantApi.getMerchantConfig();
                const merchant = merchantConfig.data.merchant as IMerchant;
                setData(merchant);
                dispatch(setMerchant(merchant));
                dispatch(setMainColor(merchant.themeColor));
                if (merchant._id) setIsConfig(isMerchantConfig(merchant._id));
            } catch {
                history.push(PATH_404);
            } finally {
                dispatch(setPopupHistory(true));
            }
        }
    };

    const isMerchantConfig = (merchantId: string) => {
        const index = configClient.findIndex((item) => item.merchantId === merchantId);
        if (index < 0) {
            return enumConfigMerchant.FALSE;
        } else {
            return enumConfigMerchant.TRUE;
        }
    };

    return <>{data && (isConfig === enumConfigMerchant.TRUE ? <PageHome /> : <PageSignIn />)}</>;
};
