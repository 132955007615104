import { Avatar, Button } from "antd";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "styled-components";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles/Swiper.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import axios from "axios";
import moment from "moment";

import {
  ChatGreenIcon,
  basketDetailsNote,
  basketTrashBin,
  checkoutClock,
  checkoutShop,
  checkoutDistance,
  checkoutCheck,
  checkoutCopy,
  checkoutPayment,
  cancelCloseIcon,
  cancelGreenDoneIcon,
} from "@components";
import { IInfo } from "@interfaces";
import { enumNavigation } from "@configs";
import { setDetailUser, store } from "@redux";
import { numberFormatter } from "@utils";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { array } from "prop-types";
import { reloadResources } from "i18next";
import QRCode from "react-qr-code";
import FirstCardImage from "./images/FirstCard.jpg";

interface IProps {
  themeColor: string;
  userInfo: IInfo | null;

  under: enumNavigation;
  handleToggleUnder: (value: enumNavigation) => void;
  handleToggleQrCode: () => void;
  handleSwitchUserSetting: () => void;
  handleSwitchTier: () => void;
  // handleCoupon: () => void;
}

export const ComponentCardInside = (props: IProps) => {
  const history = useHistory();

  //page hook
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const organization_id = store.getState().merchant?._id;
  //page variable
  const {
    themeColor,
    userInfo,

    under,
    handleToggleUnder,
    handleToggleQrCode,
    handleSwitchUserSetting,
    handleSwitchTier,
    // handleCoupon,
  } = props;

  const handleDetail = () => {
    dispatch(setDetailUser());
  };

  // Get Card Details
  let [cardDetails, setCardDetails] = useState({
    card_name: "",
    card_code: "",
    card_type: "",
    crossed_out_price: 0,
    standard_price: 0,
    card_cover_image_url: "",
    valid_affer_purchase: "",
    promotion_image_url: [],
    description: "",
    store_value_amount: 0,
    applicable_services_store_value: [
      {
        service_id: "",
        service_code: "",
        discount: { value: "", unit: "" },
      },
    ],
  });
  useLayoutEffect(() => {
    let lastURL = window.location.href.split("/").pop();
    axios
      .get(
        `${process.env.REACT_APP_SERVICE_API}cardInside?organization_code=T94&card_code=${lastURL}`
      )
      .then((data) => {
        // console.log(data);
        if (data.data.code === "1") {
          alert("Something wrong on get card details from server.");
          history.push("/cards");
          // console.log("Something wrong on get card details from server.");
        } else {
          setCardDetails(data.data.result);
        }
      })
      .catch((err) => console.log(err, "\nSomething wrong, try again!"));
  }, []);

  const addCardLocal = () => {
    localStorage.setItem("buyCardLocalStorage", JSON.stringify(cardDetails));
    // history.push("/checkout");
    history.push({
      pathname: "/checkout",
      search: "?from=buycard",
    });
  };

  return (
    <>
      {/* <div className="chatIcon">
                <ChatGreenIcon />
            </div> */}
      <div className="wholePageGray">
        <div className={"singleCard"}>
          <div className="cardInside">
            <div className="leftSide">
              <h1>{cardDetails.card_name}</h1>
              <h2>
                ฿ {cardDetails?.standard_price}
                {/* {cardDetails?.card_type === "store value"
                  ? cardDetails?.store_value_amount
                  : cardDetails?.standard_price} */}
              </h2>
              <hr />
              <p>{cardDetails.description}</p>
              <div className="validityStick">
                <p>Validity : 6 months</p>
              </div>
            </div>
            <div className="rightSide">
              <img src={cardDetails?.card_cover_image_url} />
            </div>
          </div>
        </div>
      </div>
      <div className="wholePage">
        <div className="topImage">
          {/* {cardDetails
            ? cardDetails.promotion_image_url.map((item, i) => (
                  <div className="singleImage">
                      <img src={item ? item.image_url : "Not Found"} />
                  </div>
              ))
            : null} */}
          {cardDetails?.promotion_image_url?.length > 0 &&
            cardDetails?.promotion_image_url.map((item, index) => {
              return (
                <div className="singleImage" key={index}>
                  <img src={item} />
                </div>
              );
            })}
        </div>
        <div className="botSection">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item noTopPadding">
              <a
                className="nav-link active"
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Description
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab"
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                T&C
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
              {cardDetails ? cardDetails.description : "Not Found"}
            </div>
            <div className="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              ...
            </div>
          </div>
          <div className="buttonSection">
            <button
              className="Next"
              onClick={() => {
                addCardLocal();
                // history.push("/checkout");
              }}
            >
              <span>Buy card</span>
            </button>
            <EmptyFooterSpace />
          </div>
        </div>
      </div>
    </>
  );
};

const EmptyFooterSpace = styled.div`
  height: ${(props) => `${props.theme.footer.height}`};
`;

const StyledChat = styled(ChatGreenIcon)`
  cursor: pointer;
`;
const StyledNote = styled(basketDetailsNote)`
  cursor: pointer;
`;
