import { IGetStoreExample } from "@interfaces";
import { useNotify } from "@utils";
import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { AllStoresPinIcon } from "../icon/AllStoresPinIcon";
import { AllStoresRightArrow } from "../icon/AllStoresRightArrow";
import { useDispatch } from "react-redux";
import { resetCardUse, setMerchant, store } from "@redux";

interface IProps {
  focusProvince: string;
  onChangeProvince: (type: any) => void;
  provinceList: any[];
  allStoreDetails: IGetStoreExample[];
  setShowAllStores: (type: any) => void;
  isHostClosed?: boolean;
}

export const AllStoreTab = (props: IProps) => {
  const {
    focusProvince,
    onChangeProvince,
    provinceList,
    allStoreDetails,
    setShowAllStores,
    isHostClosed,
  } = props;
  const { Option } = Select;
  const { error } = useNotify();
  const [location, setLocation] = useState<any>(null);
  const dispatch = useDispatch();
  const merchant = store.getState().merchant;

  const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371; // Earth's radius in kilometers
    const lat1Rad = (lat1 * Math.PI) / 180;
    const lon1Rad = (lon1 * Math.PI) / 180;
    const lat2Rad = (lat2 * Math.PI) / 180;
    const lon2Rad = (lon2 * Math.PI) / 180;

    const dLat = lat2Rad - lat1Rad;
    const dLon = lon2Rad - lon1Rad;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance;
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (err: any) => {
          error("Error getting location:", err);
        }
      );
    } else {
      error("Geolocation is not supported in this browser.");
    }
  }, []);

  const handleCalculateDistance = (destLat: any, destLon: any) => {
    if (location) {
      const { latitude: mobileLat, longitude: mobileLon } = location;
      const calculatedDistance: any = calculateDistance(mobileLat, mobileLon, destLat, destLon);
      return calculatedDistance;
    }
    return null;
  };

  const distances = allStoreDetails
    .filter((item) => item.location?.latlon && item.is_enable && !item.is_delete)
    .map(
      (item) =>
        item.location?.latlon && {
          id: item._id,
          distance: handleCalculateDistance(item.location.latlon[1], item.location.latlon[0]),
        }
    );

  distances.sort((a: any, b: any) => a.distance - b.distance);
  return (
    <div className="wholeGrayPage">
      <div className="province-section">
        <p className="select-province-title">Select Province :</p>
        <Select className="select-province" value={focusProvince} onChange={onChangeProvince}>
          <Option value={""}>all</Option>
          {provinceList?.map((pItem: any, pIndex: number) => {
            return (
              <Option value={pItem?.province_id} key={pIndex}>
                {pItem?.province_name_tha}
              </Option>
            );
          })}
        </Select>
      </div>
      {distances.map(({ id, distance }: any) => {
        const item: any = allStoreDetails.find((store) => store._id === id);
        return (
          <div
            className="threeTab"
            onClick={() => {
              setShowAllStores(false);
              dispatch(resetCardUse);
              dispatch(
                setMerchant({
                  ...merchant,
                  store_id: item._id,
                  store_code: item.store_code,
                  address: item.address,
                  store_name: item.store_name,
                  location: item.location,
                  business_hours: item.business_hours,
                })
              );
              localStorage.setItem("store_code", item.store_code);
            }}
            key={id}
          >
            <div className="tab shopDiv">
              <div className="leftShopDetail">
                <AllStoresPinIcon />

                <h3>
                  {item["store_name"]}{" "}
                  {distance === null ? (
                    <>
                      <span>Loading...</span>
                    </>
                  ) : (
                    <>
                      <span>{distance && distance.toFixed(1)} km Away</span>
                    </>
                  )}
                </h3>
                <div className="rightArrow">
                  <AllStoresRightArrow />
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <button
        className="btn btn-block mt-5"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setShowAllStores(false);
        }}
      >
        Click to back
      </button>
    </div>
  );
};
