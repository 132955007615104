import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import Parser from "html-react-parser";
import { useSelector } from "react-redux";

import { BLANK_IMAGE_URL } from "@configs";
import { StyledPreview, SharedButton } from "@components";
import { IBenefit, IMembershipTier } from "@interfaces";
import { selectAuth, selectMerchant } from "@redux";
import { RenderMeta } from "@utils";

interface IProps {
  data: IBenefit;
  handleToggleConfirm: () => void;
  dataRanks: IMembershipTier[];
  handleChangeImage: (swiper: SwiperCore) => void;
  currentImage: number;
}
SwiperCore.use([Navigation, Pagination]);

export const ComponentBenefitDetail = (props: IProps) => {
  //hook
  const { t } = useTranslation();
  //redux state
  const { themeColor, subColor } = useSelector(selectMerchant);
  const { showConfirm } = useSelector(selectAuth);
  // props
  const { data, handleToggleConfirm, dataRanks, handleChangeImage, currentImage } = props;

  return (
    <>
      <RenderMeta title={t("meta.benefit_detail", { name: data.name })} />
      <StyledBenefitPreview showConfirm={showConfirm}>
        <div className="page_body">
          {data.imageUrl.length ? (
            <div className="pagination">
              {currentImage}/{data.imageUrl.length}
            </div>
          ) : (
            <></>
          )}
          <div className="img-field">
            <Swiper
              slidesPerView={1}
              pagination={true}
              scrollbar={{ draggable: true }}
              onActiveIndexChange={handleChangeImage}
            >
              {data.imageUrl.length ? (
                data.imageUrl.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="img-wrap">
                      <img src={item} />
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide>
                  <div className="img-wrap">
                    <img className="empty-img" src={BLANK_IMAGE_URL} />
                  </div>
                </SwiperSlide>
              )}
            </Swiper>
          </div>

          <div className="name-field">
            <h5>{data.name}</h5>
            <StyledMField>
              {data?.levelIds?.length !== 0 &&
                dataRanks.map((item) => (
                  <>
                    {!item.isDefault && (
                      <StyledMembership key={item._id} bgColor={item.color}>
                        {item.iconUrl && <img src={item.iconUrl} />}
                        <p className="membershipName">{item.membershipName}</p>
                      </StyledMembership>
                    )}
                  </>
                ))}
            </StyledMField>
          </div>
          <div className="description-field">
            {data.description && (
              <>
                <h5>{t("page.description")}</h5>
                <div>{Parser(data.description)}</div>
              </>
            )}
            <div className="use_point">
              <StyledButton
                size="large"
                bg_color={themeColor}
                type="submit"
                onClick={handleToggleConfirm}
              >
                {t("page.redeem_benefit")}
              </StyledButton>
            </div>
          </div>
        </div>
      </StyledBenefitPreview>
    </>
  );
};

const StyledMField = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledButton = styled(SharedButton)`
  margin: 24px auto;
`;

const StyledMembership = styled.div<{ bgColor: string }>`
  background-color: ${(p) => p.bgColor};
  margin-right: 8px;
  color: white;
  font-weight: 600px;
  width: fit-content;
  max-width: 100px;
  padding: 4px 8px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  span {
    width: fit-content;
    min-width: 26px;
  }
  img {
    width: 12px;
    height: 12px;
  }
  p.membershipName {
    margin-bottom: 0px;
    margin-left: 6px;
    font-size: 8px;

    font-weight: bold;
    color: #ffffff;
  }
`;

const StyledBenefitPreview = styled(StyledPreview)`
  .description-field {
    margin: 10px 0 0 0;
  }
`;
