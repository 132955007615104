import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Parser from "html-react-parser";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import styled, { CSSProperties } from "styled-components";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

import { SharedButton, StyledPreview } from "@components";
import { selectAuth, selectMerchant } from "@redux";
import { ICoupon } from "@interfaces";
import { BLANK_IMAGE_URL } from "@configs";
import { RenderMeta } from "@utils";

interface IProps {
  coupon: ICoupon;
  handleReceiveCoupon: () => void;
  style?: CSSProperties;
  handleUseCoupon: () => void;
  handleChangeImage: (swiper: SwiperCore) => void;
  currentImage: number;
  couponCollected: boolean;
}

SwiperCore.use([Navigation, Pagination]);

export const ComponentCouponDetail = (props: IProps) => {
  //page props
  const {
    coupon,
    handleReceiveCoupon,
    style,
    handleUseCoupon,
    currentImage,
    handleChangeImage,
    couponCollected,
  } = props;
  //page Hooks
  const { t } = useTranslation();
  const { themeColor, subColor } = useSelector(selectMerchant);
  const { showConfirm } = useSelector(selectAuth);

  //page variable
  const dateFormFormat = moment(coupon?.startDate).format("yyyy/MM/DD HH:mm");
  const dateToFormat = moment(coupon?.endDate).format("yyyy/MM/DD HH:mm");

  return (
    <>
      <RenderMeta title={t("meta.coupon_detail", { name: coupon?.name })} />
      <StyledCouponPreview
        mainColor={themeColor}
        subColor={subColor}
        style={style}
        showConfirm={showConfirm}
      >
        <div className="page_body">
          <div className="body">
            <div className="text_name">
              <div className="name">{coupon?.name || t("page.name")}</div>
              <div className="date_text">
                {dateFormFormat} - {dateToFormat}
              </div>
              {couponCollected ? (
                <div className="nick_name">{t("page.collected")} &gt;</div>
              ) : (
                <div className="nick_name" onClick={handleReceiveCoupon}>
                  {t("page.collect_coupon")} &gt;
                </div>
              )}
            </div>
          </div>
          <div className="code">
            <div className="coupon_code">{t("page.coupon_code")}:</div>
            <div className="number">{coupon?.code}</div>
          </div>
          <div className="content">
            <Swiper slidesPerView={1} pagination={true} onActiveIndexChange={handleChangeImage}>
              {coupon.imageUrl.length ? (
                <div className="pagination_coupon">
                  {currentImage}/{coupon.imageUrl.length}
                </div>
              ) : (
                <></>
              )}
              {coupon.imageUrl.length ? (
                coupon.imageUrl.map((item, index) => (
                  <SwiperSlide key={index}>
                    <img className="image" src={item} />
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide>
                  <img className="image" src={coupon.imageUrl[0] || BLANK_IMAGE_URL} />
                </SwiperSlide>
              )}
            </Swiper>
            {coupon.guideline && <div className="guideline">{Parser(coupon.guideline)}</div>}
          </div>
          <div className="btn-main">
            <StyledButton
              size="large"
              bg_color={themeColor}
              type="submit"
              onClick={handleUseCoupon}
            >
              {t("page.use_coupon")}
            </StyledButton>
          </div>
        </div>
      </StyledCouponPreview>
    </>
  );
};

const StyledButton = styled(SharedButton)`
  margin-top: 16px;
`;

const StyledCouponPreview = styled(StyledPreview)`
  .swiper-slide.swiper-slide-active {
    border-radius: 6px;
  }
`;
