import styled from "styled-components";
// import { DEFAULT_LANGUAGE } from "@configs";
// console.log(DEFAULT_LANGUAGE);
export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${(p) => p.theme.breakPoints.breakMobileMax};
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
    &.boxed {
        overflow: hidden;
        margin-right: auto;
        margin-left: auto;
    }
    @media (min-width: 992px) {
        &.boxed {
            max-width: 95%;
        }
    }
    .workspace {
        height: ${(props) => `calc(100vh - ${props.theme.footer.height})`};
        width: 100%;
        display: flex;
        position: relative;
        max-width: 100%;
        flex-grow: 1;
        flex-direction: row;
        /* height: fit-content; */
    }
    .workspace > .ant-layout {
        overflow-x: hidden;
    }
`;

export const StyledPageInner = styled.div<{ showConfirm?: boolean }>`
    margin: 0 auto;
    position: relative;
    background-color: #fff;
    min-height: 100vh;
    position: relative;
    overflow: auto;
    ::-webkit-scrollbar {
        display: none;
    }
    ${(p) => {
        if (p.showConfirm) return "height:100vh; overflow: hidden;";
    }}
`;
