import { Datepicker } from "@mobiscroll/react";
import { styled } from "styled-components";

interface IProps {
  pickerChange: (time: any) => void;
  valid: any[];
  timeStep: number;
}

export const ComponentBasketTimePicker = (props: IProps) => {
  const {
    pickerChange,
    valid,
    timeStep
  } = props;
  return (
    <>
      <StyleDateTime>
        <Datepicker
          controls={['time']}
          touchUi={true}
          display="inline"
          stepMinute={timeStep}
          timeFormat="HH:mm"
          onChange={pickerChange}
          valid={valid}
          theme="windows"
          circular={false}
        />
      </StyleDateTime>
    </>
  );
};

const StyleDateTime = styled.div`
  margin-top: 20px;
  .mbsc-disabled {
    visibility: hidden;
  }
`;