import { IGetBookingList, IGetStoreDetail, IGetStoreList, IGetVatDetail } from "@interfaces";
import axiosClient from "./axiosClient";
import storefrontAxiosClient from "./storeFrontAxiosClient";

export const merchantApi = {
    getTrafficSourceId: (id: string) => {
        const url = `/traffic-source/${id}/qrcode`;
        return axiosClient.get(url);
    },
    getCouponId: (id: string) => {
        const url = `/coupons/${id}/qrcode`;
        return axiosClient.get(url);
    },
    getFreePointId: (id: string) => {
        const url = `/free-points/${id}/qrcode`;
        return axiosClient.get(url);
    },
    getMerchantConfig: () => {
        const url = "/auth/merchant-config";
        return axiosClient.get(url);
    },
    getInitMerchant: (merchantId: string) => {
        const url = "/auth/merchant-config";
        const config = {
            headers: {
                "merchant-id": merchantId,
            },
        };
        return axiosClient.get(url, config);
    },
    uploadImage: (params: FormData) => {
        const url = "/upload";
        return axiosClient.post(url, params);
    },
    getVatDetail: (params: IGetVatDetail) => {
        const url = "organization/getVatDetail";
        return storefrontAxiosClient.get(url, { params });
    },
    getStoreList: (params: IGetStoreList) => {
        const url = 'store/getStores';
        return storefrontAxiosClient.get(url, { params });
    },
    getStoreDetail: (params: IGetStoreDetail) => {
        const url = "store/getStore";
        return storefrontAxiosClient.get(url, { params });
    },
    getProvince: () => {
        const url = 'geos/getAllProvince';
        return storefrontAxiosClient.post(url);
    },
    getBookingList: (params: IGetBookingList) => {
        const url = "booking/clientGetBookings";
        return storefrontAxiosClient.get(url, { params });
    },
};
