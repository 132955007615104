import axios from "axios";
const queryString = require("query-string");

import {
  logout,
  removeConfigClient,
  resetMerchant,
  setAuth,
  store,
  tokenConfigClient,
} from "@redux";
import { enumClientIdEnum, enumHttpHeader } from "@configs";
import { IConfigClient, IMerchant } from "@interfaces";

const storefrontAxiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_STORE_ADMIN,

  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },

  paramsSerializer: (params) => queryString.stringify(params),
});

storefrontAxiosClient.interceptors.request.use((config) => {
  const token = store.getState().auth.auth?.accessToken;
  config.headers["access-token"] = token;
  config.headers[enumHttpHeader.ClientId] = enumClientIdEnum.USER;
  if (!config.headers[enumHttpHeader.MerchantId]) {
    config.headers[enumHttpHeader.MerchantId] =
      store.getState().auth.merchantId || store.getState().merchant._id;
  }
  if (store.getState().page.trafficSourceId) {
    config.headers["traffic-source-id"] = store.getState().page.trafficSourceId;
  }

  return config;
});

storefrontAxiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response;
    }
    return response;
  },

  async (error: any) => {
    // Handle errors
    if (error.response && error.response.status === 401) {
      //refreshToken
      const refreshTk = store.getState().auth.auth?.refreshToken?._id;
      if (!refreshTk) {
        store.dispatch(logout);
      }
      const configClient: IConfigClient[] = store.getState().configClient;
      const index = configClient.findIndex((item) => {
        if (item.token.refreshToken) return item?.token?.refreshToken._id === refreshTk;
      });
      const merchantId = store.getState().merchant?._id;
      if (index >= 0) {
        if (
          configClient[index].merchantId &&
          merchantId &&
          configClient[index].merchantId === merchantId
        ) {
          const refreshTokenBody = { refreshToken: refreshTk };
          const config = {
            headers: {
              "client-id": enumClientIdEnum.MERCHANT,
            },
          };
          try {
            const res = await axios.post(
              process.env.REACT_APP_API_URL + "auth/login-refresh-token",
              refreshTokenBody,
              config
            );
            if (res.status === 200) {
              store.dispatch(setAuth(res.data));
              const newToken = res.data.accessToken;
              store.dispatch(tokenConfigClient({ index: index, accessToken: newToken }));
              const originalRequest = error.config;

              originalRequest.headers["access-token"] = newToken;
              return Promise.resolve(storefrontAxiosClient(originalRequest));
            }
          } catch {
            store.dispatch(removeConfigClient(index));
            store.dispatch(setAuth(null));
          }
        }
      } else {
        store.dispatch(setAuth(null));
      }
    } else if (error.response && error.response.status === 403) {
      const merchantId = store.getState().merchant._id;
      // if (!enableAccess) {
      //     store.dispatch(resetConfigClient());
      //     store.dispatch(logout());
      // }
      const config = {
        headers: {
          "merchant-id": merchantId,
          "client-id": "rewarding-user-site",
        },
      };
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL + "auth/merchant-config", config);
        const data = res.data.merchant as IMerchant;
        if (!data.enableAccess) {
          store.dispatch(resetMerchant());
          store.dispatch(logout());
          // window.location.href = PATH_404;
        }
      } catch {
        // window.location.href = PATH_404;

        store.dispatch(resetMerchant());
        // window.location.href = PATH_404;
      }
    }
    throw error;
  }
);

export default storefrontAxiosClient;
