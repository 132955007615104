import page from "./page";
import message from "./message";
import validation from "./validation";
import meta from "./meta";
import month from "./month";

export const TRANSLATIONS_EN = {
    page,
    message,
    validation,
    meta,
    month,
};
