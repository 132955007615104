import { Avatar, Button, Icon, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles/Swiper.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import axios from "axios";
const { Option } = Select;
import {
  IconPin,
  IconCall,
  arrow,
  redArrowButton,
  allServices,
  IconHairCut,
  IconManicure,
  IconEyebrows,
  IconStaffTab,
  WalkinTurnE,
  PinDetailsIcon,
  PhoneStoreDetailsIcon,
  openningIcon,
} from "@components";
import {
  ICardUse,
  IGetStoreExample,
  IInfo,
  IMerchant,
  IPaginationNext,
  IProvince,
  IVatDetail,
} from "@interfaces";
import {
  PATH_HOME,
  PATH_MYBOOKING,
  PATH_SHOME,
  enumNavigation,
  PAGE_START,
  PAGINATION,
} from "@configs";
import {
  getInfo,
  resetCardUse,
  selectAuth,
  selectMerchant,
  setAuth,
  setCurrentPath,
  setDetailUser,
  setMainColor,
  setMerchant,
  setToken,
  store,
} from "@redux";
import { useEffect, useLayoutEffect, useState } from "react";
import moment from "moment";
import { merchantApi, organizationAPI, serviceApi } from "@api";
import { useNotify } from "@utils";
import { AllStoreTab } from "./home/AllStoreTab";
interface ICate {
  category: string;
  is_enable: boolean;
  _id: string;
}

interface IServiceExist {
  cover: string;
  title: string;
  description: string;
  price: number;
  service_code: string;
  category: string;
}

const INITAL_PAGE = {
  xpage: PAGE_START,
  xlimit: PAGINATION,
};

export const ComponentTestHome = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { themeColor } = useSelector(selectMerchant);
  let [banner, setBanner] = useState([]);
  let [staff, setStaff] = useState([]);
  const { error } = useNotify();
  const [Services, setServices] = useState(true);
  const [StaffTab, setStaffTab] = useState(false);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [ShowAllStores, setShowAllStores] = useState(false);
  const [ShowStoreDetails, setShowStoreDetails] = useState(false);
  const [fromCard, setFromCard] = useState<boolean>(false);

  const { token } = useParams<{ token: string }>();
  const params = new URLSearchParams(window.location.search);
  const goTo = params.get("goto");
  const organization_id = store.getState().merchant?._id;
  const organization_code = useSelector(selectMerchant).subCode;
  const cardUse: ICardUse = store.getState().cardUse;
  const [vatDetail, setVatDetail] = useState<IVatDetail>();
  const userInfo = store.getState().auth.userInfo;
  let [allStoreDetails, setAllStoreDetails] = useState<IGetStoreExample[]>([]);
  const [provinceList, setProvinceList] = useState<IProvince[]>([]);
  const [focusProvince, setFocusProvince] = useState<string>("");
  const [cateList, setCateList] = useState<ICate[]>([]);
  const [focusCate, setFocusCate] = useState<string>("");
  const merchantId = store.getState().merchant?._id;
  const orgInfo = useSelector((state: any) => state.merchant);
  const orgCode = useSelector(selectMerchant).subCode;
  const today = new Date();
  const dayOfWeek = today.toLocaleDateString(undefined, { weekday: "long" });
  const [storesPagination, setStoresPagination] = useState<IPaginationNext>(INITAL_PAGE);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [finishMerchant, setFinishMerchant] = useState<boolean>(false);

  // useEffect(() => {
  //   if (!token) {
  //     if (vatDetail?.is_step_choose_service_first === false && finishMerchant) {
  //       fetchStoreList();
  //     }
  //   }
  //   resetBasket();
  // }, [focusProvince, vatDetail, finishMerchant]);

  // useEffect(() => {
  //   if (!token) {
  //     fetchStoreList();
  //   }
  // }, [orgCode]);
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   if (!orgInfo.store_id && storesPagination.xpage !== 1) {
  //     fetchStoreList();
  //     setShowAllStores(true);
  //   }
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [storesPagination]);

  const getUserInfo = async () => {
    await dispatch(setToken(token));
    await dispatch(getInfo());
    dispatch(
      setAuth({
        accessToken: token,
        merchantId: merchantId || "",
        refreshToken: null,
        userPermissions: [],
      })
    );
    if (userInfo) {
      if (merchantId) {
        const merchantConfig = await merchantApi.getInitMerchant(merchantId);
        const merchant = merchantConfig.data.merchant as IMerchant;
        await dispatch(setMerchant(merchant));
        setFinishMerchant(true);
        dispatch(setMainColor(merchantConfig.data.merchant.themeColor));
        if (goTo === "mybooking") {
          history.push(PATH_MYBOOKING);
        } else {
          history.push(PATH_SHOME);
        }
      }
    }


  };

  // useEffect(() => {
  //   if (token) {
  //     getUserInfo();
  //   } else if (userInfo) {
  //     if (vatDetail) {
  //       if (!orgInfo.store_id) {
  //         if (vatDetail?.is_step_choose_service_first) {
  //           fetchServiceNoStore();
  //         } else {
  //           setShowAllStores(true);
  //         }
  //       }
  //     }
  //   }
  // }, [token, merchantId, vatDetail]);

  const getAllProvince = async () => {
    const res = await merchantApi.getProvince();
    setProvinceList(res.data.result);
  };

  const fetchStoreList = async () => {
    try {
      if (!hasMoreData) {
        return;
      }
      let res = await merchantApi.getStoreList({
        organization_code: organization_code || orgInfo.subCode,
        xpage: storesPagination.xpage,
        xlimit: 9999,
        province_id: focusProvince,
      });
      const newStores = res.data.result;
      if (storesPagination.xpage === 1) {
        setAllStoreDetails(res.data.result);
      } else {
        if (newStores.length === 0 || newStores.length < storesPagination.xlimit) {
          setHasMoreData(false);
        } else {
          setAllStoreDetails([...allStoreDetails, ...newStores]);
        }
      }
      // if (orgInfo.store_id === "" || !orgInfo.store_id) {
      //   dispatch(
      //     setMerchant({
      //       ...orgInfo,
      //       _id: res.data.result[0].organization_id,
      //     })
      //   );
      // }
    } catch (err: any) {
      error(err.message);
    }
  };

  const resetBasket = async () => {
    //
  };

  const onChangeProvince = (value: any) => {
    setFocusProvince(value);
  };

  const loadCate = async () => {
    const res = await organizationAPI.getProductCategorys({
      organization_code: orgCode || orgInfo.subCode,
    });
    if (res.data.error === "0") {
      setCateList(res.data.result);
    }
  };

  const serviceLoad = async () => {
    try {
      let res = await serviceApi.getStaffSelection();
      if (serviceLoading !== true) {
        for (let i = 0; i < res.data.length; i++) {
          let newBanner = res.data[i];
          newBanner = banner;
          newBanner.push({
            cover: res.data[i].image_url_list[0].image_url,
            title: res.data[i].service_name,
            description: res.data[i].description,
            price: res.data[i].standard_price,
            service_code: res.data[i].service_code,
          });
          setBanner(newBanner);
        }
      }
      setServiceLoading(true);
    } catch (err) {
      console.error(err, "\nSomething wrong, try again!");
    }
  };

  useEffect(() => {
    // getAllProvince();
    // loadCate();
    // fetchVatDetail();
    // serviceLoad();
  }, []);

  // useEffect(() => {
  //   if (vatDetail?.is_step_choose_service_first === false && token === undefined) {
  //     getBothServiceAndStaff();
  //   }
  // }, [orgInfo]);

  const fetchVatDetail = async () => {
    let res = await merchantApi.getVatDetail({ organization_id: organization_id });
    setVatDetail(res.data.result);
  };

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_SERVICE_API}staffSelection`)
  //     .then((data) => {
  //       for (let i = 0; i < data.data.length; i++) {
  //         let newStaff = data.data[i];
  //         newStaff = staff;
  //         newStaff.push({
  //           cover: data.data[i].image_url,
  //           name: data.data[i].name,
  //           position: data.data[i].position,
  //           staff_code: data.data[i].staff_code,
  //         });
  //         setStaff(newStaff);
  //       }
  //     })
  //     .catch((err) => console.error(err, "\nSomething wrong, try again!"));
  // }, []);

  let [getBothLoading, setGetBothLoading] = useState(false);
  let [serviceExist, setServiceExist] = useState<IServiceExist | any>([]);
  let [staffExist, setStaffExist] = useState([]);

  useEffect(() => {
    let arr: IServiceExist[] = [
      {
        cover: "/images/defaultService.jpg",
        title: "test title",
        description: "for test discription",
        price: 0,
        service_code: "test_code",
        category: "",
      },
      {
        cover: "/images/defaultService.jpg",
        title: "test title 2",
        description: " 2 for test discription",
        price: 0,
        service_code: "test_code2",
        category: "",
      }
    ];
    setServiceExist(arr);
  }, []);

  const getBothServiceAndStaff = async () => {
    try {
      const res = await axios.get(
        `https://api-store-admin.rocket-tech.app/1.0.0/clientSite/getServiceAndStaffOnStore?organization_code=${organization_code || orgInfo.subCode
        }&store_code=${orgInfo.store_code}`
      );
      let newBanner: any = [];
      let newStaff: any = [];
      for (let i = 0; i < res.data.result.service_list?.length; i++) {
        let imgCover = "/images/default_service.png";

        if (res.data.result.service_list[i]?.image_url_list?.length > 0) {
          if (typeof res.data.result.service_list[i]?.image_url_list[0] === "string") {
            imgCover = res.data.result.service_list[i]?.image_url_list[0];
          } else {
            imgCover = res.data.result.service_list[i]?.image_url_list[0].image_url;
          }
        }
        newBanner.push({
          cover: imgCover,
          title: res.data.result.service_list[i].service_name,
          description: res.data.result.service_list[i].description,
          price: res.data.result.service_list[i].standard_price,
          service_code: res.data.result.service_list[i].service_code,
          category: res.data.result.service_list[i].category,
        });
      }
      for (let i = 0; i < res.data.result.staff_list?.length; i++) {
        // let newStaff = res.data.result.staff_list[i];
        // newStaff = staffExist;
        newStaff.push({
          cover: res.data.result.staff_list[i].image_url,
          name: res.data.result.staff_list[i].staff_name,
          position: res.data.result.staff_list[i].position,
          staff_code: res.data.result.staff_list[i].staff_code,
        });
      }
      if (cardUse?.current_path === "/cardsSV") {
        setFromCard(true);
        newBanner = [];
        let objByCard = JSON.parse(localStorage.getItem("bookByCard") || "{}");
        if (objByCard?.applicable_services_store_value) {
          objByCard?.applicable_services_store_value.forEach((item: any) => {
            const result = res?.data?.result?.service_list?.filter(
              (value: any) => value.service_code === item?.service_code
            );
            if (result.length > 0) {
              let imgCover = "/images/default_service.png";
              if (item?.service_detail?.image_url_list.length > 0) {
                if (typeof item?.service_detail?.image_url_list[0] === "string") {
                  imgCover = item?.service_detail?.image_url_list[0];
                } else {
                  imgCover = item?.service_detail?.image_url_list[0].image_url;
                }
              }
              newBanner.push({
                cover: imgCover,
                title: item?.service_detail?.service_name,
                description: item?.service_detail?.description,
                price: item?.service_detail?.standard_price,
                service_code: item?.service_detail?.service_code,
                category: item?.service_detail?.category || "",
                // category:
              });
            }
          });
        }
        dispatch(setCurrentPath("/cards"));
      }
      setServiceExist(newBanner);
      setStaffExist(newStaff);
      setGetBothLoading(true);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchServiceNoStore = async () => {
    try {
      await null;
      let res = await serviceApi.getServiceList({
        organization_code: orgCode || "",
        xpage: 1,
        xlimit: 99,
        category: "",
      });
      if (res.data.error === "0") {
        let arr: any[] = [];
        res.data.result?.forEach((sItem: any) => {
          let imgCover = "";
          if (typeof sItem?.image_url_list[0] === "string") {
            imgCover = sItem?.image_url_list?.[0];
          } else {
            imgCover = sItem?.image_url_list?.[0]?.image_url;
          }
          let item = {
            cover: imgCover || "",
            title: sItem?.service_name,
            description: sItem?.description,
            price: sItem.standard_price,
            service_code: sItem.service_code,
            category: sItem.category || "",
          };
          arr.push(item);
        });
        setServiceExist([...arr]);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement || document.body;

    const triggerPosition = scrollHeight - clientHeight;

    if (scrollTop >= triggerPosition) {
      setStoresPagination({ ...storesPagination, xpage: storesPagination.xpage + 1 });
    }
  };

  return (
    <>
      {!ShowStoreDetails ? (
        <>
          {!ShowAllStores ? (
            <>
              {vatDetail?.is_step_choose_service_first === false ?
                <div className="SalonName preview fixBorderRadius">
                  <div className="topSalonName">
                    <h1
                      onClick={() => {
                        setShowAllStores(true);
                      }}
                    >
                      {orgInfo.store_name || "Unknown"} <Styledarrow />
                    </h1>
                    <div className="topStoreInfo">
                      <div
                        onClick={() => {
                          window.open(
                            `https://www.google.com/maps/search/?api=1&query=${orgInfo.location.latlon[1]},${orgInfo.location.latlon[0]}`
                          );
                        }}
                      >
                        <StyledIconPin />
                      </div>
                      <div
                        onClick={() => {
                          window.location.href = "tel:" + orgInfo.businessTel;
                        }}
                      >
                        <StyledIconCall />
                      </div>
                    </div>
                  </div>


                  <div className="botSalonName">
                    <p>
                      <span>เวลาเปิดทำการ:</span>{" "}
                      {`${orgInfo.business_hours &&
                        orgInfo.business_hours[dayOfWeek.toLowerCase()].open_hour
                        } - ${orgInfo.business_hours &&
                        orgInfo.business_hours[dayOfWeek.toLowerCase()].close_hour
                        } (${moment().format("dddd")})
                      `}
                    </p>
                  </div>
                </div>
                : <br />}
              {/* {banner.length !== 0 && fromCard === false && (
                <div className="popularSection">
                  <h1 className="secondH1">Popular {`${banner.length === 0 || ""}`}</h1>
                </div>
              )}
              {banner.length !== 0 && fromCard === false && (
                <div className="swiperSection">
                  <Swiper
                    slidesPerView={2.5}
                    spaceBetween={20}
                    grabCursor={true}
                    className="mySwiper"
                  >
                    {serviceExist
                      ? serviceExist.map((item: IServiceExist, i: number) => (
                        <SwiperSlide
                          onClick={() => {
                            history.push(`/S-Home/services/${item["service_code"]}`);
                          }}
                          key={i}
                        >
                          <img src={item["cover"] || "/images/default_service.png"} />
                          <div className="ServiceTitle">
                            <h1 className="topServiceTitle">{item["title"]}</h1>
                          </div>
                          <div className="priceAndArrow">
                            {!vatDetail?.is_not_show_price_and_skip_payment ?
                              <h2>
                                {item["price"]} <span style={{ fontWeight: "normal" }}>฿</span>
                              </h2>
                              : <h2></h2>
                            }
                            <StyledredArrowButton color={themeColor} />
                          </div>
                        </SwiperSlide>
                      ))
                      : null}
                  </Swiper>
                </div>
              )} */}
              {vatDetail?.is_display_cateogry && (
                <ul className="nav nav-tabs nav-bar topNav" id="myTab" role="tablist">
                  <li className="nav-item item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={() => {
                        setServices(true);
                        setStaffTab(false);
                      }}
                    >
                      Services
                    </a>
                  </li>
                  {fromCard === false && (
                    <li className="nav-item item">
                      <a
                        className="nav-link"
                        id="staff-tab"
                        data-toggle="tab"
                        href="#staff"
                        role="tab"
                        aria-controls="staff"
                        aria-selected="false"
                        onClick={() => {
                          setServices(false);
                          setStaffTab(true);
                        }}
                      >
                        By staff
                      </a>
                    </li>
                  )}
                </ul>
              )}

              {Services && (
                <div className="servicesSection">
                  {vatDetail?.is_display_cateogry && (
                    <div className="leftSide">
                      <ul className="nav nav-tabs leftNavs" id="myTab" role="tablist">
                        <li className="nav-item noTopPadding">
                          <a
                            className="nav-link active"
                            id="home-tab"
                            data-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            All
                          </a>
                        </li>
                        {vatDetail?.is_display_cateogry &&
                          cateList?.map((cItem: ICate, cIndex: number) => {
                            if (cItem?.is_enable) {
                              return (
                                <li className="nav-item" key={cIndex}>
                                  <a
                                    className="nav-link"
                                    id="cate-tab"
                                    data-toggle="tab"
                                    href="#cate"
                                    role="tab"
                                    aria-controls="cate"
                                    aria-selected="false"
                                    onClick={() => {
                                      setFocusCate(cItem?.category);
                                    }}
                                  >
                                    {cItem.category}
                                  </a>
                                </li>
                              );
                            }
                          })}
                      </ul>
                    </div>
                  )}
                  <div className="rightSide">
                    <div className="tab-content">
                      <div
                        className="tab-pane active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        {serviceExist.length > 0 ? (
                          serviceExist.map((item: IServiceExist, i: number) => (
                            <div
                              className="allServices"
                              // onClick={() => {
                              //   history.push(`/S-Home/services/${item["service_code"]}`);
                              // }}
                              key={i}
                            >
                              <div className="coverImage">
                                <img src={item["cover"] || "/images/default_service.png"} />
                              </div>
                              <div className="serviceInfo">
                                <h1>{item["title"]}</h1>
                                <p dangerouslySetInnerHTML={{ __html: item["description"] }} />
                                <div className="priceAndArrow">
                                  {!vatDetail?.is_not_show_price_and_skip_payment ? (
                                    <h2>{item["price"]} ฿</h2>
                                  ) : (
                                    <p></p>
                                  )}
                                  <StyledredArrowButton color={themeColor} />
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div style={{ textAlign: "center", marginTop: "50px" }}>
                            <Spin indicator={<Icon type="loading" style={{ fontSize: 34 }} />} />
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane"
                        id="cate"
                        role="tabpanel"
                        aria-labelledby="cate-tab"
                      >
                        {serviceExist?.map((item: IServiceExist, i: number) => {
                          if (item?.category === focusCate) {
                            return (
                              <div
                                className="allServices"
                                // onClick={() => {
                                //   history.push(`/S-Home/services/${item["service_code"]}`);
                                // }}
                                key={i}
                              >
                                <div className="coverImage">
                                  <img src={item["cover"]} />
                                </div>
                                <div className="serviceInfo">
                                  <h1>{item["title"]}</h1>
                                  <p>{item["description"]}</p>
                                  <div className="priceAndArrow">
                                    <h2>{item["price"]} ฿</h2>
                                    <StyledredArrowButton color={themeColor} />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {StaffTab && (
                <div
                  className="tab-pane staffSection"
                  id="staff"
                  role="tabpanel"
                  aria-labelledby="staff-tab"
                >
                  <div
                    className="tab-pane active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    {staffExist.length > 0 ? (
                      staffExist.map((item, i) => (
                        <div className="allServices" key={i}>
                          <div className="coverImage">
                            <img src={item["cover"] || "/images/default_staff.png"} />
                          </div>
                          <div className="serviceInfo">
                            <h1>{item["name"]}</h1>
                            <p className="staff-position">{item["position"]}</p>
                            <div className="priceAndArrow">
                              <p>
                                <StyledIconStaffTab />
                                <span className="staff-store">{orgInfo.store_name}</span>
                              </p>
                            </div>
                          </div>
                          <div className="staff-btn-section">
                            <Link to={"/S-Home/" + item["staff_code"]}>
                              <button className="staffButton">select</button>
                            </Link>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>staff not found</p>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <AllStoreTab
              focusProvince={focusProvince}
              allStoreDetails={allStoreDetails}
              onChangeProvince={onChangeProvince}
              provinceList={provinceList}
              setShowAllStores={setShowAllStores}
            />
          )}
        </>
      ) : (
        <div className="wholePage">
          <div className="topStoreInfo2">
            <div className="logoSide">
              <div className="circle">
                <h1>{orgInfo.store_name}</h1>
              </div>
            </div>
            <div className="rightOfLogoSide">
              <h1>{orgInfo.store_name}</h1>
              <h2>{orgInfo.remark || "-"}</h2>
            </div>
          </div>
          <div className="pinRow">
            <div className="pinSide">
              <StyledPin />
              <p>{orgInfo.address}</p>
            </div>
            <div className="imgSide">
              <img src="https://i.ibb.co/gPxS57y/Image-136.png" />
            </div>
          </div>
          <hr className="customHR" />
          <div className="phoneRow">
            <div className="leftPhoneRow">
              <StyledPhone />
              <p>{orgInfo.customer_service_tel || "-"}</p>
            </div>
            <div className="rightPhoneRow">&gt;</div>
          </div>
          <hr className="customHR" />
          <div className="openningRow">
            <div className="openningTime firstOpenning">
              <StyledOpenning />
              <div className="openningItems">
                <h1>Opening time :</h1>
                <p>Monday - Friday</p>
                <p>Saturday</p>
                <p>Sunday</p>
              </div>
            </div>
            <div className="openningTime">
              <h1>&nbsp;</h1>
              test
              {/* <p>{storeDetails.business_hours.monday.open_hour}</p>
              <p>{storeDetails.business_hours.saturday.open_hour}</p>
              <p>{storeDetails.business_hours.sunday.open_hour}</p> */}
            </div>
          </div>
          <button
            className="btn btn-block mt-5"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowStoreDetails(false);
            }}
          >
            Click to back
          </button>
        </div>
      )}
      <EmptyFooterSpace />
    </>
  );
};

const EmptyFooterSpace = styled.div`
  height: ${(props) => `${props.theme.footer.height}`};
`;

const StyledIconPin = styled(IconPin)`
  cursor: pointer;
`;

const StyledIconCall = styled(IconCall)`
  cursor: pointer;
`;

const Styledarrow = styled(arrow)`
  cursor: pointer;
`;

const StyledredArrowButton = styled(redArrowButton) <{ color: string }>`
  cursor: pointer;
`;

const StyledallServices = styled(allServices)`
  cursor: pointer;
`;

const StyledIconHairCut = styled(IconHairCut)`
  cursor: pointer;
`;

const StyledIconManicure = styled(IconManicure)`
  cursor: pointer;
`;

const StyledIconEyebrows = styled(IconEyebrows)`
  cursor: pointer;
`;

const StyledIconStaffTab = styled(IconStaffTab)`
  cursor: pointer;
`;
const StyledTurnE = styled(WalkinTurnE)`
  cursor: pointer;
`;
const StyledPin = styled(PinDetailsIcon)`
  cursor: pointer;
`;
const StyledPhone = styled(PhoneStoreDetailsIcon)`
  cursor: pointer;
`;
const StyledOpenning = styled(openningIcon)`
  cursor: pointer;
`;

const ServiceInfo = styled.div`
  color: red;
`;
