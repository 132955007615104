export default {
    redemption: "Redemption ",
    redemption_reward: "Redemption ",
    collection: "Collection ",
    _items: "items",
    hello: "Hello,",
    _points: "points",
    term_and_conditions_shop: " Terms & Conditions",
    need_update_info: "You need to update personal information!",
    free_point: "Free point",
    accept: "Accept",
    _terms_and_conditions: "terms & conditions",
    dont_have_an_account: "Don't have an account? ",
    membership_details: "Membership Details ",
    sign_up_to: "Sign up to ",
    sign_in_to: "Sign in to ",
    choose_sign_up_method: "Choose sign up method ",
    choose_sign_in_method: "Choose sign in method ",
    or: "or",
    forgot: "Forgot",
    password: " password?",
    _password: "Password",
    email: "Email",
    tel: "Tel",
    phone: "Phone number",
    name: "Name",
    remember_me: "Remember me",
    confirm_password: "Confirm password",
    reset_your_password: "Reset your password",
    email_recovery_link: "Email recovery link",
    new_password: "New password",
    confirm_new_password: "Confirm new password",
    sign_up: "Sign up",
    already_have_an_account: "Already have an account? ",
    sign_in: "Sign in",
    please_enter_OTP: "Please enter OTP",
    submit: "Submit",
    save: "Save",
    verify_email: "Verify email",
    verify_phone: "Verify phone",
    resend_OTP: "Resend OTP",
    fill_in_the_information_and_receive_special_benefits:
        "Fill in the information and receive special benefits",
    birth_date: "Birth date (dd/mm/yy)",
    accept_terms_conditions: "Accept terms & conditions",
    sign_up_failed: "Sign up Failed",
    sign_in_failed: "Sign in Failed",
    email_password_correct: "Incorrect email or password",
    login_successfully: "Login Successfully",
    login_failed: "Login Failed",

    points: "Points",
    coupon: "Coupon",
    coupons: "Coupons",
    coupon_label: "coupons",
    expire_in: "Expire in",
    _month: "month",
    use_point: "Use Point",
    benefits: "Benefits",
    history: "History",

    collected: "Collected",
    discount_coupon: "Discount coupon",
    desc: "When you buy over 100 bath",
    expiration_date: "Expiration Date",
    recceive_coupon: "Receive coupon ",
    collect_coupon: "Collect coupon ",
    coupon_code: "Coupon code",
    use_coupon: "Use coupon",
    BAHT: "BAHT",
    percent_sign: "%",

    // coupon: {
    //     collected: "Collected",
    //     discount_coupon: "Discount coupon",
    //     desc: "When you buy over 100 bath",
    //     expiration_date: "Expiration Date",
    //     recceive_coupon: "Receive coupon ",
    //     collect_coupon: "Collect coupon ",
    //     coupon_code: "Coupon code",
    //     use_coupon: "Use coupon",
    // },
    quantity_available: "Quantity ({{number}} items available)",
    have_points_left: "You'll have {{number}} points left",
    select_type: "Select type",
    descriptions: "Descriptions",
    _point: "point",

    MEMBER: "{{text}} MEMBER",
    use_points: "Use points",
    do_not_have_enough_points: "You do not have enough points",
    description: "Description",
    collect_benefit: "Collect benefit",

    done: "Done",
    transaction: "Transaction",
    coupon_title: "Coupon ({{number}} off delivery)",

    used: "Used",

    point: "Point",
    earned: "Earned",
    reward: "Reward",
    benefit: "Benefit",
    confirmation: "Confirmation",

    item: "Item",
    ticket: "Ticket",
    date_time: "Date / time",
    confirm: "Confirm",
    desConfirm: "Please show this screen or capture this screen and present them to the merchant",

    welcome: "Welcome!",
    become_welcome: "Become ",
    desc_welcome: "'s member to enjoy exclusive offers!",
    become: "Become a Member",
    qrCode: "QR code",
    qrCode_thai: "ของฉัน",

    member_id: "Member ID",

    request_connect_link: "Request connect LINE account link",
    link_line: "Link LINE account",
    become_member: "Become a member",
    fill_in_your_information: "Fill in your information in order to be a member of ",
    _and_receive: " and receive",
    star_desc: "Collect points to redeem",
    coupon_desc: "Claim coupons",
    benefit_desc: "Free membership benefits",
    draws_desc: "Join lucky draws",
    rules_of_points: "Rules of points",
    _baht_spent: "baht spent",

    phone_number: "Phone number",

    date: "Date",
    date_of_birth: "Date of birth",
    link_different: "Link different LINE account",
    request_link: "Request change LINE account link",
    _of: "of",
    _to_level: "to {{rank}} level",
    _level: " level",

    member_ShipTier: "Membership tier",

    member_shipTier_desc: "Unlock exclusive benefits with every new tier!",
    earn_points_in_a_year: "Earn {{point}} points",
    _member: " member",
    _spent: " spent",

    point_given: "Point given",

    use_free_point: "Use free point",
    collect_free_points: "Collect free points",

    phone_desc: "We send an OTP code to your mobile",
    email_desc: "We send a verification code to your email",
    enter_it_here: "Enter it here",
    resend_otp: "Resend OTP",
    resend_code: "Resend code",

    goback_home: "Lavish",
    back: "Back",
    select: "Select",
    increase: "Increase",
    decrease: "Decrease",
    add_points_from_shop: "Receive points from the shop: {{points}} points ",
    sub_points_from_shop: "Points deducted by shop: {{points}} points",
    redeem_reward: "Redeem reward",
    redeem_benefit: "Redeem benefit",
    please_sign_up_to_collect_free_point: "Please sign up to collect free point",
    please_sign_up_to_collect_coupon: "Please sign up to collect coupon",
};
