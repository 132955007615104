import styled from "styled-components";

export const StyledServiceInside = styled.div<{ mainColor: string }>`
  .topImage img {
    width: 100%;
  }
  .titleQuantity {
    padding: 18px 20px;
    background: #fff;
  }
  .topTitle h1 {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .quantity {
    margin-top: 20px;
    display: flex;
    place-content: space-between;
  }
  .quantity .leftSide h2 {
    font-size: 18px;
    margin-left: 10px;
    margin-bottom: 0;
    font-weight: 600;
  }
  .quantity .leftSide {
    display: flex;
    place-items: center;
  }
  .quantity .leftSide svg {
    width: 18px;
  }
  .quantity .rightSide {
    display: flex;
    place-items: center;
  }
  .quantity .rightSide h2 {
    font-size: 16px;
    margin-bottom: 0;
    margin: 0 8px;
    font-weight: 600;
  }
  .quantity .rightSide svg {
    cursor: pointer;
  }
  .wholePage {
    background-color: #f7f7f8;
    padding-bottom: 120px;
  }
  .staffInfo {
    margin-top: 20px;
    margin-bottom: 20px;
    background: #fff;
  }
  .position {
    display: flex;
    place-content: space-between;
    place-items: center;
    border-bottom: 1px solid #e1e1e1;
    padding: 18px 20px;
    background: #fff;
  }
  .position .leftSide {
    display: flex;
    place-items: center;
  }
  .position .leftSide h2 {
    font-size: 18px;
    margin-bottom: 0;
    margin-left: 10px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 5px;
  }
  .position .rightSide {
    display: flex;
    place-items: center;
  }
  .position .rightSide label {
    /* color: #919191; */
    color: black;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 16px;
    cursor: pointer;
    span {
      padding-left: 20px;
    }
    .staff-img {
      width: 25px;
      height: 25px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .position .leftSide img {
    width: 30px;
    border-radius: 20px;
  }
  .rmBotBorder {
    border-bottom: none;
  }
  .timeIconDiv svg {
    width: 25px;
  }
  .nav-link {
    text-align: center;
    padding: 15px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #989898;
    background: transparent;
    cursor: pointer;
    position: relative;
    font-style: normal;
    margin-right: 15px;
    height: 50px;
  }
  li {
    padding: 0 !important;
  }
  .nav-link.active {
    color: #000000;
    font-weight: 600;
    border: none !important;
    background-color: transparent;
    &::after {
      content: "";
      position: absolute;
      bottom: 8px;
      left: 45%;
      width: 50%;
      height: 2.5px;
      border-radius: 10px;
      background-color: #000;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .botSection {
    padding: 18px 20px;
  }
  .nav-tabs {
    border: none;
  }
  .nav-tabs .nav-link:hover {
    border: 1px solid transparent;
  }
  .tab-content {
    margin-top: 10px;
  }
  .tab-content .tab-pane p {
    font-weight: 600 !important;
    font-size: 16px;
    color: black;
  }
  .buttonSection {
    margin-top: 20px;
    display: flex;
    place-content: space-evenly;
    position: fixed;
    bottom: 120px;
    z-index: 10;
    width: 100%;
    padding: 0 10px;
    max-width: 480px;
  }
  .buttonSection .addService {
    background: #000;
    border-radius: 30px;
    border: 1px solid #000;
    color: #fff;
    width: 160px;
    height: 50px;
    cursor: pointer;
    font-weight: 600;
  }
  .buttonSection .Next {
    background: ${(color) => color.mainColor};
    border-radius: 30px;
    border: 1px solid ${(color) => color.mainColor};
    color: #fff;
    /* width: 160px; */
    width: 100%;
    height: 55px;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    /* place-content: space-evenly; */
    place-content: space-between;
    place-items: center;
    padding: 14px 42px;
    font-size: 18px;
    span {
      svg {
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }

  }
  .center-text-btn {
    place-content: center !important;
  }
  .buttonSection .Next span {
    font-weight: 400;
  }

  button:focus {
    outline: 0;
  }
  .swiper-pagination-bullet-active {
    background: #000;
  }
  .threeTab .tab {
    padding: 18px 20px;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    place-content: space-between;
    place-items: center;
    cursor: pointer;
  }
  .threeTab .tab h2 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 600;
  }
  .threeTab .arrowIcon {
    font-weight: 600;
    font-size: 18px;
  }
  .stylistList {
    font-size: 16px;
    background: transparent;
    border-radius: 20px;
    border: 1px solid #f22f46;
    color: #f22f46;
    width: 160px;
    height: 40px;
    cursor: pointer;
    display: flex;
    place-content: start;
    place-items: center;
    padding-left: 15px;
  }
  .stylistList:hover {
    background: #f22f46;
    color: #fff;
  }
  .css-1w13o7u-MuiTypography-root {
    // width: 32px;
    color: #000;
    font-weight: 600;
  }
  .css-l0iinn {
    margin: auto;
    z-index: 0;
    padding-right: 8px;
  }
  .css-1v994a0 {
    font-weight: 600;
    color: #000;
  }
  .css-1dozdou {
    position: relative;
  }
  .css-9reuh9-MuiPickersArrowSwitcher-root {
    position: absolute;
    width: 190px;
    place-content: space-between;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 1;
  }
  .css-7kykdr-MuiButtonBase-root-MuiIconButton-root {
    display: none;
  }
  .css-1wvgxus-MuiCalendarPicker-viewTransitionContainer {
    border-top: 1px solid #cdcdcd;
    border-bottom: 1px solid #cdcdcd;
  }
  .Mui-selected {
    background-color: #f22f46 !important;
  }
  .PrivatePickersSlideTransition-root {
    min-height: 200px;
  }
  .timeTitle h1 {
    font-size: 18px;
    font-weight: 600;
    margin: 5px;
    padding: 0;
    color: black;
  }
  .timeSection {
    display: flex;
    place-content: center;
    flex-direction: column;
    place-items: center;
    text-align: center;
    background: #fff;
  }
  .timeTitle {
    padding: 12px 0;
    text-align: center;
    border-bottom: 1px solid #cdcdcd;
    border-top: 1px solid #cdcdcd;
    width: 100%;
  }
  .timeButtons {
    background: transparent;
    border-radius: 8px;
    border: 1.5px solid #000;
    color: #000;
    width: 70px;
    height: 35px;
    cursor: pointer;
    margin: 5px;
    font-size: 14px;
    font-weight: 600;
  }
  .timeButtons.noRightMargin {
    margin-right: 0;
  }
  .times {
    margin-top: 20px;
    padding: 0 20px 18px 20px;
    display: flex;
    flex-flow: wrap;
    place-content: center;
  }
  .datePicker {
    height: 100vh;
  }
  .datePicketButSection .Next {
    width: 70%;
    margin: 30px 30px auto auto;
    padding: 30px;
    font-size: 18px;
  }
  .disabledTime {
    /* color: #fff; */
    /* border: 1px solid transparent; */
    color: #919191;
    border: 1px solid #a5a5a5;
    cursor: context-menu;
    font-weight: normal;
  }
  .activeTime {
    background: #f22f46;
    color: #fff;
    border: 1px solid transparent;
    font-weight: 600;
  }
  .quantityButton {
    background: transparent;
    border: none;
    outline: none;
  }
  .allServices {
    cursor: pointer;
  }
  .dateTopDiv {
    .css-epd502 {
      /* background-color: pink; */
      width: 100%;
      .MuiCalendarPicker-root {
        width: 100%;
        /* margin: 10px; */
      }
      .MuiDayPicker-header {
        /* background-color: orange; */
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e1e1e1;
        padding: 0px 15px;
        padding-top: 15px;
        .MuiDayPicker-weekDayLabel {
          color: black;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .MuiDayPicker-weekContainer {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        padding: 0px 15px;
        .MuiPickersDay-root {
          font-size: 12px;
        }
        .Mui-disabled {
          color: rgba(0, 0, 0, 0.38) !important;
        }
      }
      .MuiPickersCalendarHeader-root {
        .MuiPickersCalendarHeader-labelContainer {
          /* background-color: #aee4df; */
          width: 65%;
          .MuiPickersFadeTransitionGroup-root {
            margin-left: auto;
            margin-right: 0px;
          }
          text-align: right;
        }

        .MuiPickersArrowSwitcher-root {
          position: relative;
          /* margin-left: auto;
                    margin-right: 0px; */
          width: 100px;
        }
      }

      .PrivatePickersSlideTransition-root {
        min-height: 25vh;
      }
      .MuiPickersCalendarHeader-label {
        font-weight: 600;
        font-size: 20px;
        color: black;
      }
    }
  }
`;
