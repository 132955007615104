import { IGetServiceAndStaff, IGetServiceInsideDetail, IGetServiceList, IGetStaffSelectionByQuery, IGetStoresOnService } from "@interfaces";
import storefrontAxiosClient from "./storeFrontAxiosClient";
import serviceAxiosClient from "./serviceAxiosClient";


export const serviceApi = {
  getServiceList: (params: IGetServiceList) => {
    const url = "service/getServices";
    return storefrontAxiosClient.get(url, { params });
  },
  getStoreonService: (params: IGetStoresOnService) => {
    const url = "clientSite/getStoresOnService";
    return storefrontAxiosClient.get(url, { params });
  },
  getStaffSelection: () => {
    const url = "staffSelection";
    return serviceAxiosClient.get(url);
  },
  getServiceInsideDetail: (params: IGetServiceInsideDetail) => {
    const url = `serviceInsideDetails?organization_id=${params.organization_id}&service_code=${params.service_code}`;
    return serviceAxiosClient.get(url);
  },
  getStaffSelectionByQuery: (params: IGetStaffSelectionByQuery) => {
    const url = `staffSelectionByQuery?organization_id=${params.organization_id}&staff_code=${params.staff_code}`;
    return serviceAxiosClient.get(url);
  },
  getCardData: (cardCode: string) => {
    const url = `cardInside?organization_code=T94&card_code=${cardCode}`;
    return serviceAxiosClient.get(url);
  },
  getServiceSelection: () => {
    const url = "serviceSelection";
    return serviceAxiosClient.get(url);
  },
  getServiceAndStaff: (params: IGetServiceAndStaff) => {
    const url = "clientSite/getServiceAndStaffOnStore";
    return storefrontAxiosClient.get(url, { params });
  },
};