import { Progress } from "antd";

interface IProps {
    color?: string;
    size?: number;
    percentage?: number;
    showInfo: boolean;
    onClick?: () => void;
}
export const ProgressBar = (props: IProps) => {
    const { color, percentage, showInfo } = props;
    return <Progress percent={percentage} strokeColor={color} showInfo={showInfo} />;
};
