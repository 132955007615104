import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";

import {
    ComponentHeaderAuth,
    ComponentSocialMedia,
    SharedSocialLine,
    StyledContainerAuthSignUp,
    StyledContainerSignIn,
} from "@components";
import {
    addConfigClient,
    fbLogin,
    fbSignup,
    logout,
    selectAuth,
    selectConfigClient,
    selectMerchant,
    setConfigClient,
} from "@redux";
import {
    ModuleEmailSignUp,
    ModuleGoogle,
    ModuleLine,
    ModuleTelSignUp,
} from "@modules";
import {
    enumExternalMethod,
    enumExternalAuthType,
    PATH_SIGN_IN,
    FACEBOOK_INFO_URL,
    PATH_HOME,
    PATH_SIGN_UP_FORM,
    // PATH_SIGN_UP,
} from "@configs";
import { IExternalAuthState, IFBLogin, IFBSignup } from "@interfaces";
import { useNotify } from "@utils";
import axios from "axios";

const varAccessToken = "access_token";

export const PageSignUp = () => {
    //page hook
    const { t } = useTranslation();
    const history = useHistory();
    const { error, success } = useNotify();
    const dispatch = useDispatch();
    const { hash } = useLocation();
    const configClient = useSelector(selectConfigClient);
    const { _id } = useSelector(selectMerchant);

    //redux states
    const merchant = useSelector(selectMerchant);
    const { isSignUp } = useSelector(selectAuth);
    const [signinMethods, setSigninMethods] = useState<JSX.Element[]>([]);
    //page variable
    const state: IExternalAuthState = {
        type: enumExternalAuthType.SIGNUP,
    };
    const signUpMethods = merchant?.signUpSettings?.signUpMethods;
    const checkMethodSignUpSocial =
        signUpMethods?.includes(enumExternalMethod.GOOGLE);
    const checkMethodSignUpEmail = signUpMethods?.includes(enumExternalMethod.EMAIL);
    const checkMethodSignUpTel = signUpMethods?.includes(enumExternalMethod.TEL);

    useEffect(() => {
        if (hash && hash.includes(varAccessToken)) {
            const hashObj = hash.split("&").reduce((res: any, item) => {
                const parts = item.split("=");
                res[parts[0]] = parts[1];
                return res;
            }, {});
            const accessToken = hashObj?.access_token || undefined;
            if (accessToken) {
                callbackGetFbInfo(accessToken);
            }
        }
    }, []);

    const callbackGetFbInfo = async (accessToken: string) => {
        const url = `${FACEBOOK_INFO_URL}&access_token=${accessToken}`;
        try {
            const res = await axios.get(url);
            if (res.data) {
                let response;
                if (!isSignUp) {
                    const fbLoginData: IFBLogin = {
                        userId: res.data?.id,
                        token: accessToken,
                    };

                    response = await dispatch(fbLogin(fbLoginData));
                    success(t("page.login_successfully"));
                    //WHAT: wrap function in create async thunk
                    //@ts-ignore
                    unwrapResult(response);
                } else {
                    const fbSignupData: IFBSignup = {
                        userId: res.data?.id,
                        token: accessToken,
                    };
                    response = await dispatch(fbSignup(fbSignupData));
                    //WHAT: wrap function in create async thunk
                    //@ts-ignore
                    unwrapResult(response);
                }
                //@ts-ignore
                if (!response?.payload.accessToken) {
                    error(t("page.email_password_correct"));
                } else {
                    //@ts-ignore
                    const authData = response.payload;
                    const index = configClient.findIndex((item) => item.merchantId === _id);
                    if (index >= 1) {
                        dispatch(
                            setConfigClient({
                                index,
                                configClient: {
                                    merchantId: _id || "",
                                    token: authData,
                                },
                            })
                        );
                    } else {
                        dispatch(
                            addConfigClient({
                                merchantId: _id || "",
                                token: authData,
                            })
                        );
                    }
                    !isSignUp ? history.push(PATH_HOME) : history.push(PATH_SIGN_UP_FORM);
                }
            } else {
                // dispatch(logout());
                error(t("message.sign_failed"));
            }
        } catch (err: any) {
            if (err.response) {
                const mess = err.response?.data?.error?.message || err.response?.data?.errors;
                error(mess);
            } else error(t("message.sign_failed"));
            // history.push(PATH_SIGN_UP);
        }
    };

    const errorHandler = (errors: any) => {
        //handle Error
        if (errors.response) {
            const { data, status } = errors.response;
            if (data?.message && status !== 500) {
                error(data?.message);
            } else {
                error(t("page.sign_in_failed"));
            }
            dispatch(logout());
        }
    };

    useEffect(() => {
        // if (signUpMethods?.includes(enumExternalMethod.LINE)) {
        //     signinMethods.push(<ModuleLine state={state} />);
        // }
        // if (signUpMethods?.includes(enumExternalMethod.GOOGLE)) {
        //     signinMethods.push(<ModuleGoogle errorHandler={errorHandler} state={state} />);
        // }
        setSigninMethods([...signinMethods]);
    }, []);

    const handleSignIn = () => {
        history.push(PATH_SIGN_IN);
    };

    return (
        <StyledContainerSignIn color={merchant.themeColor}>
            <ComponentHeaderAuth>
                <>
                    <StyledContainerAuthSignUp bg_color={merchant.themeColor}>
                        <div className="title_auth">
                            {t("page.sign_up_to")} <br /> {merchant.name}
                        </div>
                        {checkMethodSignUpSocial && (
                            <div className="choose_auth">{t("page.choose_sign_up_method")}</div>
                        )}
                        <ComponentSocialMedia socials={signinMethods} />
                        
                        {checkMethodSignUpEmail ? (
                            <ModuleEmailSignUp />
                        ) : checkMethodSignUpTel ? (
                            <ModuleTelSignUp />
                        ) : (
                            <></>
                        )}

                        {/* <ModuleEmailSignIn /> */}
                    </StyledContainerAuthSignUp>
                    {/* <ModuleEmailSignIn handleForgotPassword={handleForgotPassword} /> */}
                    <div className="sign_in">
                        {t("page.already_have_an_account")}
                        <span onClick={handleSignIn}>{t("page.sign_in")}</span>
                    </div>
                </>
            </ComponentHeaderAuth>
        </StyledContainerSignIn>
    );
};
