import styled from "styled-components";

import { IButton } from "@interfaces";

export const SharedButton = styled.button<IButton>`
    background-color: ${(p) => p.bg_color};
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    height: 48px;
    font-size: 20px;
    border: none;

    ${(p) => {
        if (p.size === "large") return "width:100%;";
    }}
    ${(p) => {
        if (p.width) return `width:${p.width};`;
    }}
    ${(p) => {
        if (p.size === "small") return "min-width: 156px;";
    }}
    ${(p) => {
        if (p.color) return `color:${p.color};`;
    }}
   
    &:disabled {
        opacity: 0.75;
    }
`;

export const StyleSocialButton = styled.div<{ color?: string }>`
    cursor: pointer;
    background-color: ${(props) => props?.color || "#fff"};
    padding: 10px 0;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    transition: all 0.5;
    height: 56px;

    .social-icon {
        width: 36px;
        margin: auto;
    }
`;

interface IPropPreview {
    color: string;
    text: string;
}

export const SharedButtonPreview = (props: IPropPreview) => {
    const { color, text } = props;
    return <StyledButtonPreview color={color}>{text}</StyledButtonPreview>;
};

const StyledButtonPreview = styled.div<{ color: string }>`
    background-color: ${(p) => p.color};
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    padding: 12px 0;
    color: #ffffff;
    width: 100%;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
`;
