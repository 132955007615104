import { Avatar, Button } from "antd";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "styled-components";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import axios from "axios";

import {
  Coupon,
  IconStarCircle,
  IconCoinSub,
  IConUser,
  ProgressBar,
  IconBook,
  IconLoyalty,
  IconProducts,
  IconViewBooking,
  IconPoints,
  IconCoupons,
} from "@components";
import { IInfo } from "@interfaces";
import { enumNavigation } from "@configs";
import { setDetailUser, store } from "@redux";
import { numberFormatter } from "@utils";
import { useEffect, useLayoutEffect, useState } from "react";

interface IProps {
  themeColor: string;
  userInfo: IInfo | null;

  under: enumNavigation;
  handleToggleUnder: (value: enumNavigation) => void;
  handleToggleQrCode: () => void;
  handleSwitchUserSetting: () => void;
  handleSwitchTier: () => void;
  // handleCoupon: () => void;
}

const styleAvatarUser: CSSProperties = {
  transform: "scale(2)",
  right: "10%",
  top: "-8%",
  left: "auto",
};

export const ComponentHome = (props: IProps) => {
  //page hook
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //page variable
  const {
    themeColor,
    userInfo,

    under,
    handleToggleUnder,
    handleToggleQrCode,
    handleSwitchUserSetting,
    handleSwitchTier,
    // handleCoupon,
  } = props;

  const organization_id = store.getState().merchant?._id;

  const handleDetail = () => {
    dispatch(setDetailUser());
  };
  const [imageBanner, setImageBanner] = useState("");
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVICE_API}imageBanner?organization_id=${organization_id}&store_code=store_a`
      )
      .then((data) => {
        const banner = data.data.result[0].image_url;
        setImageBanner(banner);
      })
      .catch((err) => console.log(err, "\nSomething wrong, try again!"));
  }, []);

  return (
    <>
      <div className="background"></div>
      <div className="preview">
        {userInfo?.avatar ? (
          <div onClick={handleSwitchUserSetting}>
            <Avatar
              className="circle-avatar-user"
              shape="circle"
              alt="Avatar"
              size={62}
              src={userInfo?.avatar}
            />
          </div>
        ) : (
          <IConUser
            className="ant-avatar"
            style={styleAvatarUser}
            onClick={handleSwitchUserSetting}
          />
        )}
        <div className="profile-info">
          <div className="avatar_name">
            <div className="hello">{userInfo?.contact?.fullName && "Hello"} </div>
            <div className="name">{userInfo?.contact?.fullName} </div>
          </div>
          <div className="user-actions">
            {/* <div className="star" onClick={handleToggleQrCode}>
                            <IconStarCircle color={themeColor} />
                        </div> */}
            <div className="rank-layout">
              <StyledRank onClick={handleDetail} color={userInfo?.memberTier?.color || "#000"}>
                {userInfo?.memberTier?.iconUrl ? (
                  <img src={userInfo?.memberTier?.iconUrl} />
                ) : (
                  <span className="space"></span>
                )}
                <span>{userInfo?.memberTier?.tierName}</span> &gt;
              </StyledRank>
            </div>
          </div>
        </div>
        <div className="user-progress">
          <StyledProgressBar percentage={70} color={themeColor} showInfo={false} />
          <div className="progress-info">
            <p>NO. 2017 002 589</p>
            <p className="progress-spend">
              Spend <span>100฿</span> to upgrade to Platinum
            </p>
          </div>
        </div>
        <div className="star_coupans">
          <div className="star_point">
            <StyledIconBook color={themeColor} onClick={handleSwitchTier} />
            <span className="point" onClick={handleSwitchTier}>
              Book
            </span>
            {/* <span className="desc" onClick={handleSwitchTier}>
                            {t("page._points")}
                        </span> */}
          </div>
          <div className="star_point">
            <IconLoyalty
              color={themeColor}
              onClick={() => handleToggleUnder(enumNavigation.COUPON_MERCHANT)}
            />
            <span
              className="point"
              onClick={() => handleToggleUnder(enumNavigation.COUPON_MERCHANT)}
            >
              Loyalty
            </span>
            {/* <span
                            className="desc"
                            onClick={() => handleToggleUnder(enumNavigation.COUPON_MERCHANT)}
                        >
                            {t("page.coupons")}
                        </span> */}
          </div>
          <div className="star_point">
            <IconProducts
              color={themeColor}
              onClick={() => handleToggleUnder(enumNavigation.COUPON_MERCHANT)}
            />
            <span
              className="point"
              onClick={() => handleToggleUnder(enumNavigation.COUPON_MERCHANT)}
            >
              Products
            </span>
            {/* <span
                            className="desc"
                            onClick={() => handleToggleUnder(enumNavigation.COUPON_MERCHANT)}
                        >
                            {t("page.coupons")}
                        </span> */}
          </div>
        </div>
      </div>

      <div className="imageBannerSection">
        <img src={imageBanner} />
      </div>

      <div className="preview view-booking">
        <StyledIconViewBooking />
        <div className="viewBookingtext">View booking &gt;</div>
      </div>

      <div className="preview previewPoints">
        <div className="topPoints">
          <StyledIconPoints />
          <p>Points</p>
        </div>
        <div className="botPoints">
          <p>
            <span className="h3">425</span> points
          </p>
          <Button type="danger">Use points</Button>
        </div>
      </div>

      <div className="preview view-booking couponText">
        <p>
          <StyledIconCoupons />
          <span>Coupons</span>
        </p>
        <div className="viewBookingtext">View all coupons &gt;</div>
      </div>

      <div className="nav-bar">
        <div
          className={under === enumNavigation.USER_POINT ? `item under` : "item"}
          onClick={() => {
            handleToggleUnder(enumNavigation.USER_POINT);
          }}
        >
          {t("page.use_point")}
        </div>
        <div
          className={under === enumNavigation.BENEFIT ? `item under` : "item"}
          onClick={() => {
            handleToggleUnder(enumNavigation.BENEFIT);
          }}
        >
          {t("page.benefits")}
        </div>
        <div
          className={under === enumNavigation.COUPON_MERCHANT ? `item under` : "item"}
          onClick={() => {
            handleToggleUnder(enumNavigation.COUPON_MERCHANT);
          }}
        >
          {t("page.coupons")}
        </div>
        <div
          className={under === enumNavigation.HISTORY ? `item under` : "item"}
          onClick={() => {
            handleToggleUnder(enumNavigation.HISTORY);
          }}
        >
          {t("page.history")}
        </div>
      </div>
    </>
  );
};

const StyledRank = styled.div<{ color: string }>`
  .space {
    width: 4px;
    height: 5px;
  }
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 11px;
  color: #ffffff;
  background-color: ${(p) => p.color};
  padding: 4px 8px;
  width: fit-content;
  margin: 0 auto;
  border-radius: 16px;
  img {
    width: 12px;
    margin-right: 4px;
  }
  span {
    display: inline-block;
    margin-right: 4px;
  }
  svg {
    position: relative;
    top: -1px;
  }
`;

const StyledIconStarSub = styled(IconCoinSub)`
  cursor: pointer;
`;

const StyledCoupon = styled(Coupon)`
  cursor: pointer;
`;

const StyledProgressBar = styled(ProgressBar)`
  cursor: pointer;
`;

const StyledIconBook = styled(IconBook)`
  cursor: pointer;
`;

const StyledIconLoyalty = styled(IconLoyalty)`
  cursor: pointer;
`;

const StyledIconProducts = styled(IconProducts)`
  cursor: pointer;
`;

const StyledIconViewBooking = styled(IconViewBooking)`
  cursor: pointer;
`;

const StyledIconPoints = styled(IconPoints)`
  cursor: pointer;
`;

const StyledIconCoupons = styled(IconCoupons)`
  cursor: pointer;
`;
