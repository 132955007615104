import { LayoutError } from "@layouts";
import { IRoute } from "@interfaces";
import {
  PageError404,
  PageSignUpOTP,
  PageResetPasswordEmail,
  PageResetPassword,
  PageLineLogin,
  PageHome,
  PageSHome,
  PageCards,
  PageSHomeStaff,
  PageServiceInside,
  PageCardInside,
  PageBasket,
  PageCheckout,
  PageMyBooking,
  PageCoupon,
  PageReward,
  PageBenefit,
  PageWelcome,
  PageUserSetting,
  PageTierDetail,
  PageFreePoint,
  PageSignIn,
  PageSignUp,
  PageSignUpFrom,
  PageVerify,
  PageSwitchHistoryPublic,
  PageCallbackFB,
  PageTestHome,
} from "@pages";

export const PATH_HOME = "/";
export const PATH_TEST_HOME = "/test-home";
export const PATH_SHOME = "/S-Home";
export const PATH_CARDS = "/cards";
export const PATH_BASKET = "/basket";
export const PATH_CHECKOUT = "/checkout";
export const PATH_MYBOOKING = "/mybooking";
export const PATH_404 = "/*";

//sign-up
export const PATH_SIGN_UP = "/sign-up";
export const PATH_OTP = "/otp";
export const PATH_SIGN_UP_FORM = "/sign-up/form";
//sign-in
export const PATH_SIGN_IN = "/sign-in";

//reset password
export const PATH_RESET_PASSWORD_EMAIL = "/reset-password/email";
export const PATH_RESET_PASSWORD = "/reset-password";
//line
export const PATH_LINE_LOGIN = "/line-login";
//traffic source
export const PATH_TRAFFIC_SOURCE = "/traffic-source";
//coupon
export const PATH_COUPON = "/coupon";

//reward
export const REWARD = "/reward";
//benefit
export const PATH_BENEFIT = "/benefit";
//benefit
export const PATH_FREE_POINT = "/free-point";
//welcome
export const PATH_WELCOME = "/welcome";
//user
export const PATH_USER_SETTING = "/user/setting";
export const PATH_USER_VERIFY = "/user/verify";
//tier
export const PATH_TIER_DETAIL = "/tier/detail";
//client-link
export const PATH_CLIENT_LINK = "/client-link";

export const PATH_HISTORY_PUBLIC = "/history";

export const PATH_CALLBACK_FB = "/fb/callback";

export const routes: Array<IRoute> = [
  //home
  // { path: PATH_HOME, component: PageHome, exact: true },
  { path: PATH_HOME, component: PageSHome, exact: true },
  { path: PATH_TEST_HOME, component: PageTestHome, exact: true },
  //Shome
  { path: PATH_SHOME, component: PageSHome, exact: true },
  { path: PATH_HOME + "auth/:token", component: PageSHome, exact: true },
  { path: PATH_CARDS, component: PageCards, exact: true },
  { path: PATH_CARDS + "/:card_code", component: PageCardInside, exact: true },
  { path: PATH_SHOME + "/:staff_code", component: PageSHomeStaff, exact: true },
  { path: PATH_SHOME + "/:staff_code/:service_code", component: PageServiceInside, exact: true },
  { path: PATH_SHOME + "/services/:service_code", component: PageServiceInside, exact: true },
  { path: PATH_CHECKOUT, component: PageCheckout, exact: true },
  { path: PATH_MYBOOKING, component: PageMyBooking, exact: true },
  // Basket
  { path: PATH_BASKET, component: PageBasket, exact: true },

  //sign-up
  { path: PATH_SIGN_UP, component: PageSignUp, exact: true },
  { path: PATH_OTP, component: PageSignUpOTP, exact: true },
  { path: PATH_SIGN_UP_FORM, component: PageSignUpFrom, exact: true },
  //sign in
  { path: PATH_SIGN_IN, component: PageSignIn, exact: true },
  { path: PATH_LINE_LOGIN, component: PageLineLogin, exact: true },

  //reset password
  { path: PATH_RESET_PASSWORD_EMAIL, component: PageResetPasswordEmail, exact: true },
  { path: PATH_RESET_PASSWORD + "/:resetPasswordId", component: PageResetPassword, exact: true },
  //traffic source
  // { path: PATH_TRAFFIC_SOURCE + "/:id", component: PageTrafficSource, exact: true },
  //coupon source
  { path: PATH_COUPON + "/:id", component: PageCoupon, exact: true },
  { path: PATH_COUPON + "/:id/:idParent", component: PageCoupon, exact: true },
  //Reward source
  { path: REWARD + "/:id", component: PageReward, exact: true },
  //Benefit source
  { path: PATH_BENEFIT + "/:id", component: PageBenefit, exact: true },
  //Benefit source
  { path: PATH_FREE_POINT + "/:id", component: PageFreePoint, exact: true },
  //welcome
  { path: PATH_WELCOME, component: PageWelcome, exact: true },
  //user
  { path: PATH_USER_SETTING, component: PageUserSetting, exact: true },
  { path: PATH_USER_VERIFY + "/:type", component: PageVerify, exact: true },
  //tier
  { path: PATH_TIER_DETAIL, component: PageTierDetail, exact: true },
  //client-link
  // { path: PATH_CLIENT_LINK + "/:id", component: PageClientLink, exact: true },
  //histories
  { path: PATH_HISTORY_PUBLIC, component: PageSwitchHistoryPublic, exact: true },
  // fb callback
  { path: PATH_CALLBACK_FB, component: PageCallbackFB, exact: true },
  { component: PageError404, exact: false, layout: LayoutError },
];
export const normalRoutes = [
  PATH_HOME,
  PATH_SHOME,
  PATH_CARDS,
  PATH_BASKET,
  PATH_CHECKOUT,
  PATH_MYBOOKING,
  PATH_SIGN_UP_FORM,

  REWARD,
  PATH_TIER_DETAIL,
  PATH_USER_SETTING,
  PATH_BENEFIT,
  PATH_USER_VERIFY,
];

export const authRoutes = [
  PATH_SIGN_UP,
  PATH_OTP,
  PATH_SIGN_IN,
  PATH_RESET_PASSWORD_EMAIL,
  PATH_RESET_PASSWORD,
  // PATH_LINE_LOGIN,
  PATH_WELCOME,
  // TRAFFIC_SOURCE_QR_CODE,
  // PATH_CLIENT_LINK,
  PATH_404,
];

export const publicRoutes = [
  PATH_HISTORY_PUBLIC,
  PATH_COUPON,
  PATH_FREE_POINT,
  PATH_CLIENT_LINK,
  PATH_TRAFFIC_SOURCE,
];
