import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router";
import { ComponentSHome, ComponentQRcode, StyledSHome, ComponentTestHome } from "@components";
import { selectAuth, selectMerchant, selectNotSaveLocal, setNavigationType } from "@redux";
import { enumNavigation, PATH_TIER_DETAIL, PATH_USER_SETTING } from "@configs";

export const PageTestHome = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selectAuth);
  const { themeColor } = useSelector(selectMerchant);
  const { navigationType } = useSelector(selectNotSaveLocal);
  const [showQrCode, setShowQrCode] = useState<boolean>(false);

  const handleToggleUnder = (values: enumNavigation) => {
    dispatch(setNavigationType(values));
  };

  const handleToggleQrCode = () => {
    setShowQrCode(!showQrCode);
  };

  const handleSwitchUserSetting = () => {
    history.push(PATH_USER_SETTING);
  };

  const handleSwitchTier = () => {
    history.push(PATH_TIER_DETAIL);
  };

  return (
    <StyledSHome mainColor={themeColor}>
      {!showQrCode && <ComponentTestHome />}
    </StyledSHome>
  );
};
