import { Checkbox, Icon, Input, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./styles/Swiper.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import axios from "axios";
import PaymentSuccess from "./images/PaymentSuccess.svg";

import {
  WhiteIconPin,
  basketDetailsNote,
  basketTrashBin,
  basketStar,
  basketCoupon,
  basketCard,
  basketCoinsIcon,
  arrow,
  ComponentBasketTimePicker,
} from "@components";
import { ICardUse, IInfo, IPrebookingBody, IVatDetail, IbookingService } from "@interfaces";
import { enumNavigation } from "@configs";
import { selectMerchant, setDetailUser, store } from "@redux";
import { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { TextField } from "@mui/material";
import { merchantApi, serviceApi } from "@api";
import moment from "moment";
// import "file:./src/lib/mobiscroll-package/mobiscroll.min.css";
// import "@mobiscroll/react/dist/css/mobiscroll.react.scss";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
// import "../../public/mobiscroll.react.min.css";
import { BackIcon } from "./icon/BackIcon";

interface IProps {
  themeColor: string;
  userInfo: IInfo | null;

  under: enumNavigation;
  handleToggleUnder: (value: enumNavigation) => void;
  handleToggleQrCode: () => void;
  handleSwitchUserSetting: () => void;
  handleSwitchTier: () => void;
  // handleCoupon: () => void;
}
interface IPrebookingServiceItem {
  service_code: string;
  duration: {
    value: number;
    unit: string;
  };
  variant: string;
  consumption: number;
  booking_staff: IBookingStaff[];
}
interface IBookingStaff {
  staff_code: any;
}
interface IPayloadPrebooking {
  organization_id: string;
  profile_id: string;
  store_code: string;
  service_date: string;
  service_time: string;
  note: string;
  booking_service: IPrebookingServiceItem[];
}

interface IBookingReturn {
  before_vat_total: number;
  booking_code: string;
  cash_change: number;
  cash_receive: number;
  discount_in_baht_by_point: number;
  discount_regular: number;
  earn_points: number;
  grand_total: number;
  net: number;
  note: string;
  raw_grand_total: number;
  rounding: string;
  service_charge: number;
  service_charge_percent: number;
  service_date: string;
  service_time: string;
  store_name: string;
  sum_discount: number;
  summary_net_price: number;
  used_points: number;
  vat_percent: number;
  vat_total: number;
  vat_type: string;
}

interface IBodyGetSlot {
  // organization_id: string;
  organization_code: string;
  username: string;
  profile_id?: string;
  store_code: string | null;
  service_date: string | null | undefined;
  booking_service: IPrebookingServiceItem[];
}
export const ComponentBasket = (props: IProps) => {
  const history = useHistory();

  //page hook
  const { t } = useTranslation();
  const [basketDetail, setBasketDetail] = useState<IBookingReturn | null>(null);
  const dispatch = useDispatch();
  const [tab, setTab] = useState<string>("basket");
  const [value, setValue] = useState<Date | null>(new Date());
  let minDate = new Date(new Date().getTime());
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  let [TimeSlots, setTimeSlots] = useState([]);
  const [timePick, setTimePick] = useState("12:00");
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [note, setNote] = useState<string>("");
  const [contact, setContact] = useState<string>("");
  const [bookedModal, setBookedModal] = useState<boolean>(false);
  const [validTime, setValidTime] = useState<any[]>([]);
  const [timeStep, setTimeStep] = useState<number>(15);
  const [onLoadTimeSlots, setOnLoadtTimeSlots] = useState<boolean>(false);
  const [active, setActive] = useState("");
  const [storeDetail, setStoreDetail] = useState({
    address: "",
    store_name: "",
    organization_name: "",
  });
  let [cardDetails, setCardDetails] = useState({
    card_name: "",
    card_code: "",
    card_type: "",
    crossed_out_price: 0,
    standard_price: 0,
    valid_affer_purchase: "",
    description: "",
    applicable_services_store_value: [
      {
        service_id: "",
        service_code: "",
        discount: { value: "", unit: "" },
      },
    ],
    promotion_image_url: [{ image_url: "" }],
  });
  const [vatDetail, setVatDetail] = useState<IVatDetail>();

  //page variable
  const {
    themeColor,
    userInfo,

    under,
    handleToggleUnder,
    handleToggleQrCode,
    handleSwitchUserSetting,
    handleSwitchTier,
    // handleCoupon,
  } = props;
  const [bookingList, setBookingList] = useState<IPrebookingBody | null>(null);
  const merchant = useSelector(selectMerchant);
  let bookByCard = JSON.parse(localStorage.getItem("bookByCard") || "{}");

  let basketItem: any = [];
  const preBookingBody = JSON.parse(localStorage.getItem("preBookingBody") || "{}");
  if (preBookingBody.booking_service) {
    const getLocalStorage: any = preBookingBody.booking_service;
    // basketItem.push(getLocalStorage);

    for (let i = 0; i < getLocalStorage.length; i++) {
      basketItem.push(getLocalStorage);
    }
  }
  const getStore = async (localStoreCode: string) => {
    let res = await merchantApi.getStoreDetail(
      {
        organization_code: organization_code || "",
        store_code: localStoreCode || "",
      });
    if (res.data.error === "0") {
      let outStore = {
        address: res?.data?.result?.address,
        store_name: res?.data?.result?.store_name,
        organization_name: res?.data?.result?.organization_data?.name,
      };
      setStoreDetail(outStore);
    } else {
      alert("Something is wrong with get store");
      console.error(res);
    }
  };

  useEffect(() => {
    if (preBookingBody !== null) {
      setBookingList(preBookingBody);
      let price = 0;
      preBookingBody?.booking_service?.forEach((item: IbookingService) => {
        if (item?.total_price) {
          price = price + item?.total_price * item?.consumption;
        }
      });
      setTotalPrice(price);
      fetchPreBooking();
    }
    let localStoreCode = localStorage.getItem("store_code");
    if (localStoreCode) {
      getStore(localStoreCode || "");
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Your logic to log something before leaving the page
      localStorage.removeItem("preBookingBody");
    };

    const handlePopstate = (event: PopStateEvent) => {
      // Your logic to log something when the user clicks the back button
      localStorage.removeItem("preBookingBody");
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopstate);

    return () => {
      // Cleanup: Remove the event listeners when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);


  const fetchPreBooking = () => {
    let payload: IPayloadPrebooking = {
      organization_id: organization_id,
      profile_id: profile_id,
      store_code: preBookingBody.store_code,
      service_date: preBookingBody.service_date,
      service_time: preBookingBody.service_time,
      note: preBookingBody.note,
      booking_service: [],
    };
    preBookingBody?.booking_service?.forEach((sItem: IPrebookingServiceItem) => {
      let item: any = {
        service_code: sItem.service_code,
        duration: sItem.duration,
        variant: sItem.variant,
        consumption: sItem.consumption,
        booking_staff: [
          {
            staff_code: sItem.booking_staff,
          },
        ],
      };
      payload.booking_service.push(item);
    });
    const headers = {
      "Content-Type": "application/json",
      "access-token": token,
    };

    axios
      .post(`${baseUrl}booking/preBooking`, payload, { headers: headers })
      .then((data) => {
        if (data.data.error) {
          let resPreBooking = data.data.result;
          setBasketDetail(resPreBooking.booking_return);
        } else {
          alert("Something is wrong with booking on backend and time");
        }
      })
      .catch((err) => console.error(err, "\nSomething wrong on Pre-Booking, try again!"));
  };
  // basketItem[0].splice(removeItem, 1);
  const deleteItem = (index: number) => {
    let leftItem = bookingList?.booking_service.filter((item, id) => id !== index);
    // let leftItem = bookingList?.booking_service.splice(index, 1);

    if (leftItem?.length === 0) {
      preBookingBody.booking_service = [];
      localStorage.removeItem("preBookingBody");
    } else {
      preBookingBody.booking_service = leftItem;
      localStorage.setItem("preBookingBody", JSON.stringify(preBookingBody));
    }
    let price = 0;
    leftItem?.forEach((item: IbookingService) => {
      if (item?.total_price) {
        price = price + item?.total_price * item?.consumption;
      }
    });
    setTotalPrice(price);
    setBookingList({ ...preBookingBody });
    // window.location.reload();
  };

  //@ts-ignore
  const token = store.getState().auth.auth?.accessToken;
  const profile_id = store.getState().auth.userInfo?._id;
  const username = store.getState().auth?.userInfo?.username;
  const organization_id = store.getState().merchant?._id;
  const organization_code = useSelector(selectMerchant).subCode;
  const cardUse: ICardUse = store.getState().cardUse;

  useEffect(() => {
    if (cardUse.current_path === "/serviceInside") {
      if (cardUse.channel === "online") {
        setTab("timeslot");
      }
      getQuotes();
      if (cardUse.card_code) {
        getCardData(cardUse.card_code);
      }
    }
    fetchVatDetail();
  }, []);

  // Create Booking
  const headers = {
    "Content-Type": "application/json",
    "access-token": token,
  };
  const baseUrl = process.env.REACT_APP_API_STORE_ADMIN;

  const getCardData = async (cardCode: string) => {
    let res = await serviceApi.getCardData(cardCode);
    if (res.data.result) {
      setCardDetails(res.data.result);
    } else {
      alert("Something wrong on get card details from server.");
    }
  };

  const formatDate = (type: string, isTime: boolean, focusDate?: string) => {
    const MONTHlIST = [
      t("month.jan"),
      t("month.feb"),
      t("month.mar"),
      t("month.apr"),
      t("month.may"),
      t("month.jun"),
      t("month.jul"),
      t("month.aug"),
      t("month.sep"),
      t("month.oct"),
      t("month.nov"),
      t("month.dec"),
    ];
    const service_date = value?.toISOString().split("T")[0] || "";
    const service_time = timePick;
    let date = value;
    if (focusDate) {
      date = new Date(focusDate);
    }
    let outdate = "";



    if (date) {
      if (type === "monthtext") {
        outdate = [date.getDate(), MONTHlIST[date.getMonth()], date.getFullYear()].join(" ");
      } else {
        outdate = [
          ("0" + date.getDate()).slice(-2),
          ("0" + (date.getMonth() + 1)).slice(-2),
          date.getFullYear(),
        ].join("/");
      }

    }
    if (isTime) {
      return `${outdate} (${service_time})`;
    } else {
      return outdate;
    }


  };

  const pickerChange = (time: any) => {
    let selectTime = moment(time.value);
    setTimePick(selectTime.format("HH:mm"));
  };

  const createBooking = () => {
    let localBasket: IPrebookingBody = JSON.parse(localStorage.getItem("preBookingBody") || "{}");
    localBasket.service_date = value?.toISOString().split("T")[0] || "";
    localBasket.service_time = timePick;
    localBasket.contact_text = "";
    localBasket.spend_point_discount = 0;
    let date = value;
    let outdate = "";
    if (date) {
      outdate = [
        date.getFullYear(),
        ("0" + (date.getMonth() + 1)).slice(-2),
        ("0" + date.getDate()).slice(-2),
      ].join("-");
    }
    localBasket.service_date = outdate;
    axios
      .post(`${baseUrl}booking/createBooking`, localBasket, { headers: headers })
      .then((data) => {
        if (data.data.error !== "1") {
          localStorage.removeItem("preBookingBody");
          // setBookingList(null);
          // localStorage.setItem(
          //     "bookingDetails",
          //     JSON.stringify(data.data.result.booking_return)
          // );
          localStorage.removeItem("bookByServiceCodeCounter");
          // localStorage.setItem("bookingData", JSON.stringify(data?.data?.result));

          localStorage.setItem("bookingData", JSON.stringify(data?.data?.result));
          // history.push("/checkout");
          setBookedModal(true);
        } else {
          alert(data.data.msg);
        }
      })
      .catch((err) => console.error(err, "\nSomething wrong on Booking, try again!"));


  };

  const createBookingCard = () => {
    let serDate = value?.toISOString().split("T")[0] || "";
    let serTime = timePick;
    setTab("basket");
  };

  const handleConfirmUsage = () => {
    let serDate = value?.toISOString().split("T")[0] || "";
    let serTime = timePick;
    let payload = {
      api_type: "do",
      organization_id: organization_id,
      profile_id: profile_id,
      card_use_code: cardUse.card_use_code,
      store_code: preBookingBody?.store_code,
      service_date: serDate,
      service_time: serTime,
      note: "",
      booking_service: preBookingBody?.booking_service,
      spend_point_discount: 0,
      contact_text: "",
    };
    axios
      .post(`${baseUrl}booking/createBookingApplyCard`, payload, { headers: headers })
      .then((data) => {
        if (data?.data?.error !== "1") {
          setOpenModal(true);
        }
      })
      .catch((err) => console.error(err, "\nSomething wrong on Time Slots, try again!"));
  };

  function filterWeekends(date: Date) {
    // Return false if Saturday or Sunday
    let dayOfWeek = date.getDay();
    let isWeekend = dayOfWeek === 6 || dayOfWeek === 0; // 6 = Saturday, 0 = Sunday
    return dayOfWeek === null;
  }

  const fetchVatDetail = async () => {
    let res = await merchantApi.getVatDetail({ organization_id: organization_id });
    setVatDetail(res.data.result);
    if (res.data.result.is_not_show_price_and_skip_payment) {
      setTab("timeslot");
      // setTab('confirmInfo');
      getQuotes();
    }
  };

  const modifyBooking = () => {
    const bookingLocal = JSON.parse(localStorage.getItem("bookingDetails") || "{}");
    // const bookingCode = bookingLocal.booking_code;
    // const modifyBookingBody = {
    //    organization_id: organization_id,
    //     booking_code: bookingCode,
    //     profile_id: profile_id,
    //     store_code: StoreCode,
    //     service_date: value?.toISOString().split("T")[0],
    //     service_time: timePick,
    //     total_price: ServiceDetails.standard_price,
    //     note: "testnote",
    //     booking_service: [
    //         {
    //             service_code: lastURL,
    //             service_name: ServiceDetails.service_name,
    //             standard_price: ServiceDetails.standard_price,
    //             duration: {
    //                 value: durationValue,
    //                 unit: durationUnit,
    //             },
    //             variant: storeVariantName,
    //             stylist: Stylist,
    //             consumption: quantity,
    //             booking_staff: [
    //                 // {
    //                 //     staff_code: staffUrl[4],
    //                 // },
    //             ],
    //         },
    //     ],
    // };
    // axios
    //     .patch(`${baseUrl}booking/updateBooking`, modifyBookingBody, { headers: headers })
    //     .then((data) => {
    //         if (data.data.error !== 1) {
    //             localStorage.removeItem("modifyBookingBody");
    //             localStorage.removeItem("bookingDetails");
    //             history.push("/mybooking");
    //         } else {
    //         }
    //     })
    //     .catch((err) => console.error(err, "\nSomething wrong on Pre-Booking, try again!"));
  };

  const changeInput = (e: any) => {
    if (e.target.id === "note") {
      setNote(e.target.value);
    } else {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setContact(e.target.value);
      }
    }
  };

  const getQuotes = (newValue?: any) => {
    setOnLoadtTimeSlots(true);
    // call api slot time
    let localStoreCode = localStorage.getItem("store_code");
    let localBasket = JSON.parse(localStorage.getItem("preBookingBody") || "{}");
    let date = new Date();
    if (newValue) {
      date = newValue;
    } else {
      newValue = value;
    }
    let outdate = [
      date.getFullYear(),
      ("0" + (date.getMonth() + 1)).slice(-2),
      ("0" + date.getDate()).slice(-2),
    ].join("-");

    let body: IBodyGetSlot = {
      organization_code: organization_code || "",
      profile_id: profile_id,
      username: "",
      store_code: localStoreCode,
      service_date: outdate,
      // note: "testnote",
      booking_service: [
        // {
        //     service_code: ServiceDetails.service_code,
        //     duration: { value: durationValue, unit: durationUnit },
        //     variant: storeVariantName,
        //     consumption: quantity,
        //     booking_staff: [],
        // },
      ],
    };
    localBasket?.booking_service?.forEach((item: IPrebookingServiceItem) => {
      body.booking_service.push({
        service_code: item.service_code,
        duration: item.duration,
        variant: item.variant,
        consumption: item.consumption,
        booking_staff: item.booking_staff,
      });
    });
    setLoading(true);
    axios
      .post(`${baseUrl}booking/getBookingSlotAvailable`, body, { headers: headers })
      .then((data) => {
        let bookingslot: string = data.data.result2?.booking_duration_slot;
        if (bookingslot === "15 minutes") {
          setTimeStep(15);
        } else if (bookingslot === "30 monutes") {
          setTimeStep(30);
        } else {
          setTimeStep(60);
        }
        setTimeStep(15);
        let timeSlots = data.data.result;
        let arr: any = [];
        for (let i = 0; i < timeSlots.length; i++) {
          arr.push({
            time_text: timeSlots[i].time_text,
            is_available: timeSlots[i].is_available,
          });
        }
        setTimeSlots(arr);
        let valList: any[] = [];
        timeSlots.forEach((slot: any, sIndex: number) => {

          if (slot.is_available) {
            let thisTime = moment(slot.time_text, "HH:mm");
            valList.push({
              start: thisTime.format("HH:mm"),
              end: thisTime.format("HH:mm"),
              recurring: {
                repeat: 'daily'
              }
            });
          }
        });
        setValidTime(valList);
        setOnLoadtTimeSlots(false);
        setLoading(false);
        // setRefresh(!Refresh);
      })
      .catch((err) => console.error(err, "\nSomething wrong on Time Slots, try again!"));
  };
  return (
    <>
      {!loading && tab === "basket" && (
        <>
          <div className="topDarkBackground">
            <h1 className="text-center">{storeDetail?.organization_name}</h1>
            <div className="storeAddress">
              <h2>
                <StyledIconPin />
                {storeDetail?.organization_name} ({storeDetail?.store_name})
              </h2>
              <p>{storeDetail?.address}</p>
            </div>
          </div>
          {!localStorage.getItem("bookByServiceCodeCounter") ? (
            <>
              {!localStorage.getItem("bookByCard") ? (
                <div className="myBasket">
                  <h1 className="basketTitle">
                    My basket ({bookingList?.booking_service?.length})
                  </h1>
                  <hr className="firstHrBasket" />
                  {bookingList && bookingList.booking_service ? (
                    bookingList?.booking_service?.map((item, i) => (
                      <div className="basketItems" key={i}>
                        <div className="leftSide">
                          <div className="serviceImage">
                            <img
                              src={`${item?.image_url_list[0] || "https://i.ibb.co/T4Vvv6g/Image-90.png"
                                }`}
                            />
                          </div>
                          <div className="serviceDetails">
                            <h1>{item["service_name"]}</h1>
                            <ul>
                              <li>
                                {item["duration"]["value"]} {item["duration"]["unit"]}
                              </li>
                              <li>{item["variant"]}</li>
                              <li>{item["stylist"]}</li>
                            </ul>
                          </div>
                        </div>
                        <div className="rightSide">
                          <div className="priceQuantity">
                            <h3>
                              {!vatDetail?.is_not_show_price_and_skip_payment &&
                                <>
                                  <span>฿</span>
                                  {item["total_price"]}
                                </>
                              }
                            </h3>
                            <h4>x{item["consumption"]}</h4>
                            <div
                              onClick={() => {
                                // removeItem =
                                //     preBookingBody.booking_service.filter(
                                //         (e: any) =>
                                //             e.service_code !==
                                //             item[i]["service_code"]
                                //     );
                                // preBookingBody.total_price =
                                //     preBookingBody.total_price -
                                //     item[i]["standard_price"];
                                // removeProcess();
                                deleteItem(i);
                              }}
                            >
                              <StyledBin />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Nothing to show</p>
                  )}
                  <hr />
                  {/* <div className="staffInfo">
                    <div className="position">
                      <div className="leftSide">
                        <div className="iconWidth">
                          <StyledStar />
                        </div>
                        <h2>Points</h2>
                      </div>
                      <div className="rightSide">
                        <label>
                          Apply points{" "}
                          <span>
                            <Styledarrow color="#919191" />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="position">
                      <div className="leftSide">
                        <div className="iconWidth">
                          <StyledCoupon />
                        </div>
                        <h2>Coupon</h2>
                      </div>
                      <div className="rightSide">
                        <label>
                          Apply coupon{" "}
                          <span>
                            <Styledarrow color="#919191" />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="position rmBotBorder">
                      <div className="leftSide timeIconDiv">
                        <div className="iconWidth">
                          <StyledCard />
                        </div>
                        <h2>Card</h2>
                      </div>
                      <div className="rightSide">
                        <label>
                          Apply card{" "}
                          <span>
                            <Styledarrow color="#919191" />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div> */}
                  {!vatDetail?.is_not_show_price_and_skip_payment &&
                    <div className="totalPriceSection">
                      <label className="total">Total :</label>
                      <div className="innerTotalPrice">
                        <h4 className="totalPrice">
                          <span>฿</span>
                          {totalPrice}
                        </h4>
                      </div>
                    </div>
                  }

                </div>
              ) : (
                <div className="myBasket">
                  <h1 className="basketTitle">Card usage</h1>
                  <hr className="firstHrBasket" />
                  <div className="basketItems">
                    <div className="leftSide">
                      <div className="basketCardDetails">
                        {Object.keys(bookByCard).length > 0 && (
                          <>
                            <p>Transaction : {bookByCard?.card_name}</p>
                            <p>Quantity : {preBookingBody?.booking_service[0]?.consumption}</p>
                            <p>Booking Slot : {formatDate("booking slot", true)}</p>
                            <p>Location : {storeDetail?.store_name}</p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="myBasket">
              <h1 className="basketTitle">Card usage</h1>
              <hr className="firstHrBasket" />
              <div className="basketItems">
                <div className="leftSide">
                  <div className="basketCardDetails">
                    <p>Transaction : {cardDetails.card_name}</p>
                    <p>Quantity : 1</p>
                    <p>
                      Booking Slot : {value?.toISOString().split("T")[0]} {timePick}
                    </p>
                    <p>Location : </p>
                    {/* <p>Specific request : Somchai</p> */}
                  </div>
                </div>
              </div>
            </div>
          )}
          <hr className="grayHr" />
          <div className="basketDetailsSection">
            <div className="position">
              <div className="titleText">
                <h1>Notes</h1>
              </div>
              <div className="rightSide">
                <div className="noteButton">
                  {/* <button>Notes</button> */}
                  <p>
                    {/* <StyledNote /> */}
                    <Input
                      size="small"
                      id="note"
                      suffix={<StyledNote />}
                      value={note}
                      onChange={changeInput}
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="position removeBotBorder">
              <div className="titleText">
                <h1>Contact</h1>
              </div>
              <div className="rightSide">
                <div className="noteButton">
                  <p>
                    <Input
                      size="small"
                      id="contact"
                      suffix={<StyledNote />}
                      value={contact}
                      onChange={changeInput}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="grayHr" />
          <div className="buttonSection">
            <button
              className={vatDetail?.is_not_show_price_and_skip_payment ? "Next center-text-btn" : "Next"}
              // className="Next"
              onClick={() => {
                if (cardUse?.current_path === "/serviceInside") {
                  handleConfirmUsage();
                } else {
                  setTab("timeslot");
                  // createBooking();
                  getQuotes();
                }
              }}
            >
              {localStorage.getItem("staffCode") || cardUse?.current_path === "/serviceInside" ? (
                ""
              ) : (
                <span className="total">
                  {!vatDetail?.is_not_show_price_and_skip_payment &&
                    <>
                      <span className="baht">฿</span>
                      {totalPrice}
                    </>
                  }

                </span>
              )}
              {cardUse?.current_path === "/serviceInside" ? (
                <span>Confirm usage</span>
              ) : (
                <span>Select Time</span>
              )}
            </button>
          </div>
          <div className="checkout-modal">
            <Modal
              visible={openModal}
              // title="Confirmation slip"
              footer={null}
              width={380}
              onCancel={() => {
                history.push("/mybooking");
              }}
            // closable={false}
            >
              <div className="payment-modal-header-section">Confirmation slip</div>
              <div className="payment-modal-body">
                <div className="img">
                  <img className="cardImages rabbit" src={PaymentSuccess} />
                </div>
                <div className="text-section">Booking successful !</div>
                <div className="buttonSection">
                  <button
                    className="Next"
                    onClick={() => {
                      history.push("/mybooking");
                    }}
                    style={{ color: "white", backgroundColor: themeColor, borderColor: themeColor }}
                  >
                    <span>My bookings</span>
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </>
      )}
      {!loading && tab === "timeslot" && (
        <div className="wholePage datePicker">
          <div className="dateTopDiv">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                label="Date picker"
                views={["day"]}
                value={value}
                className="dateTimePicker"
                onChange={(newValue) => {
                  setTimeSlots([]);
                  setValidTime([]);
                  setValue(newValue);
                  // setQuotes(null);
                  getQuotes(newValue);
                  // setRefresh(!Refresh);
                }}
                // renderDay={renderWeekPickerDay}
                renderInput={(params) => <TextField {...params} className="datepicker-boom" />}
                inputFormat="'Week of' MMM d"
                minDate={minDate}
                shouldDisableDate={filterWeekends}
              />
            </LocalizationProvider>

            <div className="timeSection">
              {vatDetail?.booking_timepicker_style === "block" &&
                <>
                  <div className="timeTitle">
                    <h1>เวลาที่ต้องการใช้บริการ</h1>
                  </div>
                  {validTime.length > 0 ?
                    <ComponentBasketTimePicker
                      pickerChange={pickerChange}
                      valid={validTime}
                      timeStep={timeStep}
                    />
                    :
                    <p style={{ marginTop: 20 }}>{onLoadTimeSlots ?
                      <Spin indicator={<Icon type="loading" style={{ fontSize: 34 }} />} /> :
                      "no available time slot..."}
                    </p>
                  }
                </>
              }


              {vatDetail?.booking_timepicker_style === "list" && <div className="times paddingBottom">
                {loading ? (
                  <p style={{ marginTop: "10px" }}>
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 34 }} />} />
                  </p>
                ) : (
                  TimeSlots.map((item, i) => {
                    return (
                      <button
                        key={i}
                        className={
                          item["is_available"]
                            ? active !== i.toString()
                              ? "timeButtons"
                              : "timeButtons activeTime"
                            : "timeButtons disabledTime"
                        }
                        onClick={() => {
                          if (item["is_available"]) {
                            setTimePick(item["time_text"]);
                            setActive(i.toString());
                          }
                        }}
                      >
                        {item["time_text"]}
                      </button>
                    );
                    // if (item["is_available"] === true) {
                    //     return (
                    //         <button
                    //             key={i}
                    //             className={
                    //                 active !== i.toString()
                    //                     ? "timeButtons"
                    //                     : "timeButtons activeTime"
                    //             }
                    //             onClick={() => {
                    //                 setTimePick(item["time_text"]);
                    //                 setActive(i.toString());
                    //             }}
                    //         >
                    //             {item["time_text"]}
                    //         </button>
                    //     );
                    // }
                  })
                )}
              </div>}
            </div>
            {validTime.length > 0 &&

              <div className="buttonSection datePicketButSection">
                {/* <button className="addService">Add services</button> */}
                {/* <Link to={"/basket"}> */}
                <button
                  className={vatDetail?.is_not_show_price_and_skip_payment ? "Next center-text-btn" : "Next"}
                  onClick={() => {
                    if (cardUse?.current_path === "/serviceInside") {
                      createBookingCard();
                    } else {
                      if (merchant.prefix === "popmartth") {
                        setTab('confirmInfo');
                      } else {
                        createBooking();
                      }
                    }
                  }}
                >
                  Confirm
                  {localStorage.getItem("modifyBookingBody") ||
                    localStorage.getItem("bookByCard") ||
                    localStorage.getItem("bookByServiceCodeCounter") ? null : (
                    <span className="total">
                      {!vatDetail?.is_not_show_price_and_skip_payment &&
                        <>
                          <span className="baht">฿</span> {totalPrice}
                        </>
                      }
                      {/* {ServiceDetails.standard_price} */}
                    </span>
                  )}
                </button>
              </div>
            }

            <EmptyFooterSpace />
          </div>
        </div>
      )}
      <div className="checkout-modal">
        <Modal
          visible={bookedModal}
          // title="Confirmation slip"
          footer={null}
          width={380}
          onCancel={() => {
            history.push("/mybooking");
          }}
        // closable={false}
        >
          <div className="payment-modal-header-section">Booking Successful</div>
          <div className="payment-modal-body">
            <div className="img">
              <img className="cardImages rabbit" src={PaymentSuccess} />
            </div>
            <div className="text-section">Booking successful !</div>
            <div className="buttonSection">
              <button
                className="Next"
                onClick={() => {
                  history.push("/mybooking");
                }}
                style={{ color: "white", backgroundColor: themeColor, borderColor: themeColor }}
              >
                <span>My bookings</span>
              </button>
            </div>
          </div>
        </Modal>
      </div>
      {!loading && tab === "confirmInfo" &&
        <div className="InfoPage">
          <div className="back-icon" onClick={() => setTab("timeslot")}>
            <BackIcon color={themeColor} />
          </div>
          <div className="info-logo">
            <img src="/images/popmart_logo.png" />
          </div>

          <p className="title" >Registration Booking <br />
            For Visit & Buy Product POPMART
          </p>
          <div className="detail-section">
            <p className="detail-title">Confirm Information</p>
            <p className="detail-name">Event :</p>
            <p className="detail-value">{bookingList?.booking_service?.[0]?.service_name}</p>
            <p className="detail-name">Full Name :</p>
            <p className="detail-value">{userInfo?.fullName}</p>
            <p className="detail-name">Birthday :</p>
            <p className="detail-value">{formatDate("monthtext", false, userInfo?.contact?.dateOfBirth)}</p>
            <p className="detail-name">Phone :</p>
            <p className="detail-value">{userInfo?.contact?.phoneNumber}</p>
            <p className="detail-name">Booking Data :</p>
            <p className="detail-value">{formatDate("monthtext", true)}</p>
            <p className="detail-name">Branch :</p>
            <p className="detail-value">{bookingList?.store_name}</p>
          </div>
          <div className="info-con">
            <Checkbox />
            <span>I accept</span>
            <span className="term-con">Terms & Conditions</span>
          </div>

          <div className="btn-section">
            <button
              className="next-btn"
              onClick={createBooking}
            >
              Confirm
            </button>
          </div>
        </div>
      }

      {/* <h1>hello</h1> */}
      <EmptyFooterSpace />
    </>
  );
};

const EmptyFooterSpace = styled.div`
  height: ${(props) => `${props.theme.footer.height}`};
`;

const StyledIconPin = styled(WhiteIconPin)`
  cursor: pointer;
`;
const StyledNote = styled(basketDetailsNote)`
  cursor: pointer;
`;
const StyledBin = styled(basketTrashBin)`
  cursor: pointer;
`;
const StyledStar = styled(basketStar)`
  cursor: pointer;
`;
const StyledCoupon = styled(basketCoupon)`
  cursor: pointer;
`;
const StyledCard = styled(basketCard)`
  cursor: pointer;
`;
const StyledCoins = styled(basketCoinsIcon)`
  cursor: pointer;
`;

const Styledarrow = styled(arrow)`
  cursor: pointer;
`;