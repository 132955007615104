import { IFBLogin, IGGLogin } from "./../Interfaces/auth";
import axiosClient from "./axiosClient";
import {
    ILogin,
    ILineLogin,
    IChangePassword,
    ILineSignup,
    IFBSignup,
    IGGSignup,
    IEmailForm,
    IPhone,
    ISocialMedia,
    IResetPassword,
    IUserSetting,
    IPagination,
} from "@interfaces";
import { GOOGLE_USER_INFO } from "@configs";

export const authApi = {
    login: (params: ILogin) => {
        const url = "/auth/client-login";
        return axiosClient.post(url, params);
    },
    getInfo: () => {
        const url = "/users/profile/user";
        return axiosClient.get(url);
    },
    updateProfile: (values: IUserSetting) => {
        const url = "users/profile";
        return axiosClient.put(url, values);
    },
    refreshToken: (refreshToken: string) => {
        const url = "/auth/login-refresh-token";
        const params = {
            refreshToken,
        };
        return axiosClient.post(url, params);
    },
    lineLogin(params: ILineLogin) {
        const url = "/auth/client-line-login";
        return axiosClient.post(url, params);
    },
    lineSignup(params: ILineSignup) {
        const url = "/auth/client-line-signup";
        return axiosClient.post(url, params);
    },
    fbLogin(params: IFBLogin) {
        const url = "/auth/client-facebook-login";
        return axiosClient.post(url, params);
    },
    fbSignup(params: IFBSignup) {
        const url = "/auth/client-facebook-signup";
        return axiosClient.post(url, params);
    },
    //google
    ggLogin(params: IGGLogin) {
        const url = "/auth/client-google-login";
        return axiosClient.post(url, params);
    },
    ggSignup(params: IGGSignup) {
        const url = "/auth/client-google-signup";
        return axiosClient.post(url, params);
    },
    getUserInfoByGg(params: IPagination) {
        const url = GOOGLE_USER_INFO;
        return axiosClient.get(url, {
            params,
        });
    },
    changePassword(values: IChangePassword) {
        const url = "users/merchant/password";
        return axiosClient.put(url, values);
    },
    registerEmail: (email: string) => {
        const url = "/auth/client-register";
        const params = {
            email,
        };
        return axiosClient.post(url, params);
    },
    sendResetPassword: (email: string) => {
        const url = "/auth/send-reset-password";
        const params = {
            email,
        };
        return axiosClient.post(url, params);
    },
    resetPassword: (values: IResetPassword) => {
        const url = "/auth/reset-password";
        return axiosClient.put(url, values);
    },
    registerPhone: (value: IPhone) => {
        const url = "/auth/twilio-signup";
        return axiosClient.post(url, value);
    },
    loginPhone: (value: IPhone) => {
        const url = "/auth/twilio-login";
        return axiosClient.post(url, value);
    },
    verifyPhone: (value: IPhone) => {
        const url = "/auth/twilio-verify";
        return axiosClient.post(url, value);
    },
    resendPhone: (value: IPhone) => {
        const url = "/auth/resend-phone";
        return axiosClient.post(url, value);
    },
    createPassword: (values: IEmailForm | ISocialMedia) => {
        const url = "/auth/sign-up/form";
        return axiosClient.put(url, values);
    },
    updateLine: (values: ILineLogin) => {
        const url = "users/link-line";
        return axiosClient.put(url, values);
    },
};
