interface IProps {
    color: string;
    onClick?: () => void;
}

export const Coupon = (props: IProps) => {
    const { onClick } = props;
    return (
        <>
            <svg
                width="26"
                height="25"
                viewBox="0 0 26 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={onClick}
            >
                <path
                    d="M25.7311 13.599C25.0541 12.2802 24.2813 11.0128 23.419 9.807C22.699 8.862 21.7771 9.99699 20.9951 9.87499C20.6262 9.90509 20.2569 9.82071 19.9377 9.63335C19.6184 9.446 19.3647 9.16477 19.2111 8.828C18.9904 8.46381 18.9137 8.03031 18.9963 7.61254C19.0788 7.19477 19.3145 6.82292 19.6571 6.57C20.9261 5.695 20.7861 4.81799 20.0081 3.73699C19.5584 3.10672 19.1662 2.43737 18.8361 1.73699C18.2361 0.472992 17.4821 0.336999 16.2851 1.057C12.0111 3.625 7.68505 6.10499 3.39005 8.63799C-0.801951 11.111 -0.620918 10.086 2.02408 14.791C2.05308 14.842 2.09006 14.891 2.12406 14.939C2.54706 15.588 3.02408 16.001 3.89408 15.663C4.32644 15.4441 4.82608 15.3988 5.29081 15.5361C5.75553 15.6735 6.1502 15.9832 6.39408 16.402C6.53815 16.6139 6.63846 16.8525 6.68912 17.1037C6.73978 17.3549 6.73979 17.6137 6.68912 17.8649C6.63845 18.1161 6.53809 18.3546 6.39402 18.5666C6.24994 18.7785 6.06503 18.9595 5.85007 19.099C5.7015 19.2031 5.57726 19.3382 5.48593 19.495C5.39461 19.6518 5.33838 19.8264 5.32108 20.007C5.30378 20.1876 5.32587 20.3699 5.38578 20.5411C5.44568 20.7123 5.54197 20.8686 5.66806 20.999C6.61906 22.217 6.76806 24.073 8.51206 24.641C8.73706 24.714 9.08708 24.528 9.33408 24.384C14.5641 21.334 19.7878 18.2737 25.0051 15.203C25.5661 14.863 26.1501 14.367 25.7311 13.599ZM22.2831 14.385C18.2431 16.7637 14.1964 19.1303 10.1431 21.485C9.6431 21.772 9.06306 22.485 8.46006 21.643C8.19684 21.3664 8.02647 21.0145 7.97269 20.6365C7.91891 20.2585 7.98443 19.873 8.16007 19.534C9.69607 16.323 8.17209 13.721 4.59909 13.415C4.21434 13.4127 3.84066 13.2858 3.53415 13.0532C3.22765 12.8206 3.00484 12.4949 2.89908 12.125C2.55708 11.199 3.39908 11.05 3.92008 10.744C7.90608 8.39068 11.8977 6.04701 15.8951 3.71301C16.1951 3.53501 16.5101 3.36501 16.7331 3.24001C17.6451 3.24001 18.225 4.569 17.807 5.44C15.916 9.378 17.175 11.54 21.653 11.899C22.594 11.975 22.8421 12.589 23.0991 13.23C23.3841 13.95 22.7161 14.134 22.2831 14.389V14.385Z"
                    fill="#050505"
                />
                <path
                    d="M12.477 16.21C12.526 16.932 12.337 17.286 11.91 17.468C11.72 17.566 11.5003 17.5896 11.2939 17.5341C11.0875 17.4786 10.9092 17.348 10.794 17.168C9.70666 15.398 8.64698 13.611 7.61498 11.807C7.51883 11.6163 7.49754 11.3965 7.55528 11.1909C7.61302 10.9853 7.74563 10.8088 7.92699 10.696C8.29099 10.405 8.75995 10.305 9.05895 10.667C10.3237 12.4343 11.4657 14.2864 12.477 16.21Z"
                    fill="#050505"
                />
            </svg>
        </>
    );
};
