interface IProps {
  color: string;
}

export const BackIcon = (props: IProps) => {
  const { color = "#BC9067" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5455 4.45451C16.9848 4.89385 16.9848 5.60615 16.5455 6.0455L9.84099 12.75L16.5455 19.4545C16.9848 19.8938 16.9848 20.6062 16.5455 21.0455C16.1062 21.4848 15.3938 21.4848 14.9545 21.0455L7.45449 13.5455C7.01522 13.1062 7.01522 12.3938 7.45449 11.9545L14.9545 4.45451C15.3938 4.01516 16.1062 4.01516 16.5455 4.45451Z"
        fill={color}
      />
    </svg>
  );
};
