import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "@redux";

import {
    IAuth,
    IInfo,
    ILogin,
    ILineLogin,
    IFBLogin,
    IGGLogin,
    ILineSignup,
    IFBSignup,
    IGGSignup,
} from "@interfaces";
import { authApi } from "@api";
import { RootState } from ".";
import { enumExternalMethod } from "@configs";

export const login = createAsyncThunk<IAuth, ILogin>(
    "auth/login",
    async (values: ILogin, { rejectWithValue }) => {
        try {
            const res = await authApi.login(values);
            return res.data as IAuth;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);

export const lineLogin = createAsyncThunk<IAuth, ILineLogin>(
    "auth/lineLogin",
    async (values, { rejectWithValue }) => {
        try {
            const res = await authApi.lineLogin(values);
            const merchantId = store.getState().merchant._id;
            return { ...res.data, merchantId } as IAuth;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);

export const lineSignup = createAsyncThunk<IAuth, ILineSignup>(
    "auth/lineSignup",
    async (values, { rejectWithValue }) => {
        try {
            const res = await authApi.lineSignup(values);
            const merchantId = store.getState().merchant._id;
            return { ...res.data, merchantId } as IAuth;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);

export const fbLogin = createAsyncThunk<IAuth, IFBLogin>(
    "auth/fbLogin",
    async (values, { rejectWithValue }) => {
        try {
            const res = await authApi.fbLogin(values);
            const merchantId = store.getState().merchant._id;
            return { ...res.data, merchantId } as IAuth;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);

export const fbSignup = createAsyncThunk<IAuth, IFBSignup>(
    "auth/fbSignup",
    async (values, { rejectWithValue }) => {
        try {
            const res = await authApi.fbSignup(values);
            const merchantId = store.getState().merchant._id;
            return { ...res.data, merchantId } as IAuth;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);

export const ggLogin = createAsyncThunk<IAuth, IGGLogin>(
    "auth/google-signin",
    async (values, { rejectWithValue }) => {
        try {
            const res = await authApi.ggLogin(values);
            const merchantId = store.getState().merchant._id;
            return { ...res.data, merchantId } as IAuth;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);

export const ggSignup = createAsyncThunk<IAuth, IGGSignup>(
    "auth/google-signup",
    async (values, { rejectWithValue }) => {
        try {
            const res = await authApi.ggSignup(values);
            const merchantId = store.getState().merchant._id;
            return { ...res.data, merchantId } as IAuth;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);

export const getInfo = createAsyncThunk("auth/userInfo", async (values, { rejectWithValue }) => {
    try {
        const res = await authApi.getInfo();
        return res.data as IInfo;
    } catch (err: any) {
        return rejectWithValue(err);
    }
});

interface ILine {
    code: string | null;
    state: string;
}
interface IState {
    auth: IAuth | null;
    isRemember: boolean;
    isLoading: boolean;
    error: string;
    tokenInfoAuth: string;
    userInfo: IInfo | null;
    line?: ILine | null;
    externalLogin: enumExternalMethod | null;
    // externalType: IExternalType | null;
    merchantId: string;
    isSignUp: boolean;
    detailUser: boolean;
    showConfirm: boolean;
}

interface ISetExternalLogin {
    line?: ILine;
    type: enumExternalMethod;
    isSignUp?: boolean;
}

const initialState: IState = {
    auth: null,
    isRemember: false,
    isLoading: false,
    error: "",
    tokenInfoAuth: "",
    userInfo: null,
    externalLogin: null,
    merchantId: "",
    isSignUp: false,
    // externalType: null,
    detailUser: false,
    showConfirm: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        logout: () => {
            return initialState;
        },
        setAuth: (state, action: PayloadAction<IAuth | null>) => {
            if (action.payload) state.auth = { ...state.auth, ...action.payload };
            else state.auth = action.payload;
        },
        setToken: (state, action) => {
            if (state.auth?.accessToken) {
                state.auth.accessToken = action.payload;
            } else {
                state.auth = {
                    accessToken: action.payload,
                    refreshToken: null,
                    userPermissions: [],
                    merchantId: "",
                };
            }
        },
        setInfo: (state, action) => {
            state.userInfo = { ...state.userInfo, ...action.payload };
        },
        setRemember: (state, action: PayloadAction<boolean>) => {
            state.isRemember = action.payload;
        },
        setExternalLogin: (state, action: PayloadAction<ISetExternalLogin>) => {
            state.externalLogin = action.payload.type;
            state.line = action.payload.line;
            state.isSignUp = action.payload.isSignUp || false;
            state.isLoading = true;
        },

        setMerchantID: (state, action: PayloadAction<string>) => {
            state.merchantId = action.payload;
        },
        setDetailUser: (state) => {
            state.detailUser = !state.detailUser;
        },
        setToggleConfirm: (state) => {
            state.showConfirm = !state.showConfirm;
        },
        resetConfirm: (state) => {
            state.showConfirm = initialState.showConfirm;
        },
        resetDetailUser: (state) => {
            state.detailUser = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action: { payload: IAuth }) => {
            if (action.payload.accessToken) {
                state.auth = action.payload;
            }
            if (!state.isRemember) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                state.auth!.refreshToken = null;
            }
            state.isLoading = false;
        });

        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(login.rejected, (state) => {
            state.auth = null;
            state.isLoading = false;
        });

        builder.addCase(getInfo.fulfilled, (state, action: { payload: IInfo }) => {
            state.userInfo = action.payload;
        });

        builder.addCase(getInfo.rejected, (state) => {
            state.userInfo = null;
        });

        builder.addCase(lineLogin.fulfilled, (state, action: { payload: IAuth }) => {
            if (action.payload.accessToken) {
                state.auth = action.payload;
            }
        });

        builder.addCase(lineLogin.rejected, (state) => {
            state.auth = null;
            state.externalLogin = null;
            state.line = null;
            state.isLoading = false;
        });

        builder.addCase(lineSignup.fulfilled, (state, action: { payload: IAuth }) => {
            if (action.payload.accessToken) {
                state.auth = action.payload;
            }
        });

        builder.addCase(lineSignup.rejected, (state) => {
            state.auth = null;
            state.externalLogin = null;
            state.line = null;
            state.isLoading = false;
        });

        builder.addCase(fbLogin.fulfilled, (state, action: { payload: IAuth }) => {
            if (action.payload.accessToken) {
                state.auth = action.payload;
            }
        });

        builder.addCase(fbLogin.rejected, (state) => {
            state.auth = null;
            state.externalLogin = null;
            state.isLoading = false;
        });

        builder.addCase(fbSignup.fulfilled, (state, action: { payload: IAuth }) => {
            if (action.payload.accessToken) {
                state.auth = action.payload;
            }
        });

        builder.addCase(fbSignup.rejected, (state) => {
            state.auth = null;
            state.externalLogin = null;
            state.isLoading = false;
        });

        builder.addCase(ggLogin.fulfilled, (state, action: { payload: IAuth }) => {
            if (action.payload.accessToken) {
                state.auth = action.payload;
            }
        });

        builder.addCase(ggLogin.rejected, (state) => {
            state.auth = null;
            state.externalLogin = null;
            state.isLoading = false;
        });

        builder.addCase(ggSignup.fulfilled, (state, action: { payload: IAuth }) => {
            if (action.payload.accessToken) {
                state.auth = action.payload;
            }
        });

        builder.addCase(ggSignup.rejected, (state) => {
            state.auth = null;
            state.externalLogin = null;
            state.isLoading = false;
        });
    },
});

export const selectAuth = (state: RootState) => state.auth;

export const {
    logout,
    setAuth,
    setToken,
    setInfo,
    setRemember,
    setExternalLogin,
    setMerchantID,

    setDetailUser,
    setToggleConfirm,
    resetConfirm,
    resetDetailUser,
} = authSlice.actions;

export default authSlice.reducer;
